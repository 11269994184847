import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject, of } from 'rxjs';
import { delay, debounce, find, map, catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Permission } from '../../../core/auth/_models/permission.model';
import { Role } from '../../../core/auth/_models/role.model';

 
@Injectable({
	providedIn: 'root'
})
export class AuthonticationsService {
	public isLoading = new BehaviorSubject(false);
	constructor(private http: HttpClient) { }


	loginNEW(LoginDetails: any) {
		const httpHeaders = new HttpHeaders();
		httpHeaders.set('Content-Type', 'application/json');
		return this.http.post<any[]>(environment.BaseUrl + '/api/Login/Authontication', LoginDetails, { headers: httpHeaders })
		  .pipe(
			map((res: any[]) => {
				debugger;
			  return res;
			}),
			catchError(err => {
				debugger;
			  return null;
			})
		  );
	  }
	  roleMatch(allowedRoles): boolean {
		var isMatch = false;
		var payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
		var userRole = payLoad.role;
		allowedRoles.forEach(element => {
		  if (userRole == element) {
			isMatch = true;
			return false;
		  }
		});
		return isMatch;
	  }
	  public login(LoginDetails: any) {

		let header:HttpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': 'localhost:5000',
			'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
			'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token, content-type'
		  })
        //let header:HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

        const object = JSON.stringify(LoginDetails);
        const self = this;  
		return this.http.post<any[]>(environment.BaseUrl + 'api/Users/authenticate',JSON.stringify(LoginDetails),{headers:header} );
		//return this.http.post<any[]>(environment.BaseUrl + 'api/LoginApi/Authontication',JSON.stringify(LoginDetails),{headers:header} );
 
    }
	  
	// Authentication/Authorization
	login222(LoginDetails: any) {
		const authData = {
			value: "dd",
			
		};

		const headers = new HttpHeaders().set('Accept', 'application/json');
		return this.http.post<any[]>(environment.BaseUrl + 'api/Values/Post', authData,{headers:headers} );
	}


	login2(LoginDetails: any) {

		const headers = new HttpHeaders().set('Content-Type','application/json');
		
		return this.http.post<any[]>(environment.BaseUrl + 'api/Login/Authontication',JSON.stringify(LoginDetails),{headers:headers} );
	}


	forgotPassword( UserName: string) {
		
		let headers:HttpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': 'localhost:5000',
			'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
			'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token, content-type'
		  })

		const params = new HttpParams().set("UserName", UserName.toString());
		return this.http.post<any[]>(environment.BaseUrl + "api/Users/ForgotPasswordGET?UserName="+UserName, {
			headers: headers,
			params: params,
		}).pipe(
			map((res: any[]) => {
				
			  return res;
			}),
			catchError(err => {
				
			  return err;
			})
		  );
	  }
}

