import { DatePipe } from "@angular/common";
import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatPaginator, MatSnackBar, MatDialog } from "@angular/material";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { BehaviorSubject, Observable } from "rxjs";
import { startWith, debounceTime, distinctUntilChanged, switchMap } from "rxjs/operators";
import { LayoutUtilsService } from "../../../core/_base/crud";
import { environment } from "../../../../environments/environment";
import { ViewDispatchComponent } from "../../Modals/view-dispatch/view-dispatch.component";
import { CoffeeService } from "../../coffee.service";
import { ActualViewDisptchComponent } from "../../Modals/actual-view-disptch/actual-view-disptch.component";
import { E } from "@angular/cdk/keycodes";
import{NgSelect2Component,NgSelect2Module,Select2OptionData} from "ng-select2";

@Component({
	selector: "kt-actual-disptch-data",
	templateUrl: "./actual-disptch-data.component.html",
	styleUrls: ["./actual-disptch-data.component.scss"],
})
export class ActualDisptchDataComponent implements OnInit {
	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;

	showSpinner = false;
	public hasItems: boolean;
	totalLength: number = 119;
	pageIndex: number = 1;
	pageSize: number = 10;
	MasterId: number = 0;
	public employeeId: string = "0";
	totalLengthEmitter$ = new BehaviorSubject<number>(this.totalLength);

	masters: [];

	observable: Observable<[]>;
	loading2 = false;
	submitted = false;
	applyLeaveId: number = 0;
	masterList$: Observable<any>;
	tempmasterList$: Observable<any>;
	masterDropDown$: Observable<any>;
	empstatusfrm: FormGroup;
	employeeStatusFormShow = false;
	@Output() messageEvent = new EventEmitter<string>();

	dispatchplanList$: Observable<any[]>;
	private dispatchplanListSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

	//Pagination
	totalPages: number = 50;
	currentPage: number = 1;
	totalRecords: number = 0;
	itemsPerPage = 10;
	totalItems = 0;
	searchQuery: string = "";
	loading = false;
	loadingSubmit = false;

	actualDispatchForm: FormGroup;
	searchInput: string;
	data$: Observable<any[]>;

	statusData: any[] = [];
	statusOptions: any = {};
	statusOptionsConfig: any = {};
	minDate: Date;
	maxDate: Date;

	// New Check Sorting Colum in Asc and Desc
	sortProperty: string = 'dispatchId';
    sortOrder = 1;
	orderBy:string="DESC";

	constructor(private formBuilder: FormBuilder, private _snackBar: MatSnackBar, private datePipe: DatePipe, private spinner: NgxSpinnerService, private _router: Router, private dialog: MatDialog, public snackBar: MatSnackBar, private layoutUtilsService: LayoutUtilsService, private ref: ChangeDetectorRef, private coffeeService: CoffeeService) {
		this.dispatchplanList$ = this.dispatchplanListSubject.asObservable();
		const currentYear = new Date().getFullYear();
		this.minDate = new Date(currentYear - 20, 0, 1);
		this.maxDate = new Date(currentYear + 1, 11, 31);
	}

	onSubmit() {
		if (this.actualDispatchForm.invalid) {
			Object.values(this.actualDispatchForm.controls).forEach((control) => control.markAsTouched());
			return;
		}
		const owner_company_id = this.actualDispatchForm.get("owner_company_id").value;
		const statusid = this.actualDispatchForm.get("statusid").value;
		const orderno = this.actualDispatchForm.get("orderno").value;
		const fromDateValue = this.datePipe.transform(this.actualDispatchForm.get("fromdate").value, "MM-dd-yyyy");
		const toDateValue = this.datePipe.transform(this.actualDispatchForm.get("todate").value, "MM-dd-yyyy");
		this.ActualDispatchPlanGET(fromDateValue, toDateValue, owner_company_id, statusid, orderno);
	}
	resetform() {
		this.actualDispatchForm.reset();
		this.pageIndex=1;
		this.pageSize=10;
		

		this.ActualDispatchPlanGET(null, null, null, null, null);
	}

	ngOnInit() {
		this.actualDispatchForm = this.formBuilder.group({
			orderno: [""],
			owner_company_id: [""],
			statusid: [""],
			fromdate: ["", Validators.compose([Validators.required])],
			todate: ["", Validators.compose([Validators.required])],
		});

		var userdata = JSON.parse(localStorage.getItem("user"));
		this.statusOptionsConfig = {
			ajax: {
				url: environment.BaseUrl + "api/Owner/allDropdown",
				dataType: "json",
				delay: 250,
				headers: {
					Authorization: "Bearer " + userdata.token,
				},
				data: (params: any) => {
					return {
						searchTerm: params.term,
						page: params.page,
						type: 9,
						parentId: 0,
					};
				},
				processResults: (data: any) => {
					const results = data.map((item: any) => {
						return {
							id: item.id,
							text: item.text,
						};
					});

				results.splice(0,0,{id: 0,text: "Show All"});
					return {
						results: results,
					};
				},
				cache: true,
			},
			placeholder: "Select a Status",
			minimumInputLength: 0,
		};

		this.ActualDispatchPlanGET(null, null, null, null, null);
		this.setupSearch();
	}

	openModal(dispatchId: number) {
		const dialogRef = this.dialog.open(ActualViewDisptchComponent, {
			data: { dispatchId: dispatchId },
		});

		// You can handle dialog events here if needed
		dialogRef.afterClosed().subscribe((result) => {
			console.log("Dialog closed with result:", result);
		});
	}

	ActualDispatchPlanGET(fromDate: string, toDate: string, owner_company_id: string, statusid: string, orderno: string) {
		this.loadingSubmit = true;
		const startIndex = this.pageIndex; // Set your desired values for the parameters
		const pageSize = this.pageSize;
		const searchBy = "";
		const searchCriteria = "";
		this.spinner.show();

		const sortProperty = this.sortProperty;
		const orderBy=this.orderBy;
		
		this.coffeeService.ActualDispatchPlanGET(startIndex, pageSize, searchBy, searchCriteria, fromDate, toDate, owner_company_id, statusid, orderno,sortProperty,orderBy).subscribe((response: any[]) => {
			this.dispatchplanListSubject.next(response);
			this.loadingSubmit = false;
			this.totalPages = response[0].TotalPages;
			this.totalRecords = response[0].TotalRecords;
			this.spinner.hide();
			
		});
	}
	onPageChanged(e) {
		console.log("pageIndex>>::",this.pageIndex+"pageSize>>:",this.pageSize);
		this.pageIndex = e.pageIndex + 1;
		this.pageSize = e.pageSize;
		console.log("pageIndex>>::",this.pageIndex+"pageSize>>:",this.pageSize);
		this.ActualDispatchPlanGET(null, null, null, null, null);
	}

	getSelectOptionsConfig(): any {
		var userdata = JSON.parse(localStorage.getItem("user"));
		return {
			ajax: {
				url: environment.BaseUrl + "api/Owner/allDropdown",
				dataType: "json",
				delay: 250,
				headers: {
					Authorization: "Bearer " + userdata.token,
				},
				data: (params: any) => {
					return {
						searchTerm: params.term,
						page: params.page,
						type: 12,
						parentId: "0",
					};
				},
				processResults: (data: any) => {
					const results = data.map((item: any) => {
						return {
							id: item.id,
							text: item.text,
						};
					});
					results.splice(0,0,{id: 0,text: "Show All"});
					return {
						results: results,
					};
				},
				cache: true,
			},
			placeholder: "Select a Owners",
			minimumInputLength: 0,
		};
	}

	setupSearch() {
		//Owner
		this.data$ = new Observable<string>((observer) => {
			observer.next(this.searchInput);
		}).pipe(
			startWith(""),
			debounceTime(300),
			distinctUntilChanged(),
			switchMap((query: string) => {
				console.log("Search Query:", query);
				query = query || "";

				return this.coffeeService.dropdownAll(query, "1", "12", "0");
			})
		);
	}

	onPageChange(page) {
		this.currentPage = page;		
		this.pageIndex = page;
		//this.pageSize = page.pageSize;

		const owner_company_id = this.actualDispatchForm.get("owner_company_id").value;
		const statusid = this.actualDispatchForm.get("statusid").value;
		const orderno = this.actualDispatchForm.get("orderno").value;
		const fromDateValue = this.datePipe.transform(this.actualDispatchForm.get("fromdate").value, "MM-dd-yyyy");
		const toDateValue = this.datePipe.transform(this.actualDispatchForm.get("todate").value, "MM-dd-yyyy");				
		this.ActualDispatchPlanGET(fromDateValue, toDateValue, owner_company_id, statusid, orderno);
	}
	onSearch(query: string) {
		this.searchQuery = query;
		this.currentPage = 1;
		this.ActualDispatchPlanGET(null, null, null, null, null);
	}

	editUserData(userInfo: string) {
		localStorage.setItem("usersStorage", JSON.stringify(userInfo));
		this._router.navigate(["/coffee/usermaster"]);
	}

	onPageSizeChange(page) {
		
		console.log("currentPage>>:",this.currentPage);
		// this.currentPage = page;
		this.pageSize = page;
		this.pageIndex = 1;	
		const owner_company_id = this.actualDispatchForm.get("owner_company_id").value;
		const statusid = this.actualDispatchForm.get("statusid").value;
		const orderno = this.actualDispatchForm.get("orderno").value;
		const fromDateValue = this.datePipe.transform(this.actualDispatchForm.get("fromdate").value, "MM-dd-yyyy");
		const toDateValue = this.datePipe.transform(this.actualDispatchForm.get("todate").value, "MM-dd-yyyy");
		this.ActualDispatchPlanGET(fromDateValue, toDateValue, owner_company_id, statusid, orderno);
	}

	// check sorting
	sortBy(property: string) {
        this.sortOrder = property === this.sortProperty ? (this.sortOrder * -1) : 1;
        this.sortProperty = property;
       
		if(this.sortOrder===1)
		{
			this.orderBy="ASC";
			console.log("sortOrderASC>>:",this.orderBy);

		}
		if(this.sortOrder===-1)
			{
				this.orderBy="DESC";
				console.log("sortOrderDESC>>:",this.orderBy);
	
			}
		console.log("sortOrder>>:", this.sortOrder);
		console.log("sortProperty>>:", this.sortProperty);
		
		const owner_company_id = this.actualDispatchForm.get("owner_company_id").value;
		const statusid = this.actualDispatchForm.get("statusid").value;
		const orderno = this.actualDispatchForm.get("orderno").value;
		const fromDateValue = this.datePipe.transform(this.actualDispatchForm.get("fromdate").value, "MM-dd-yyyy");
		const toDateValue = this.datePipe.transform(this.actualDispatchForm.get("todate").value, "MM-dd-yyyy");
		this.ActualDispatchPlanGET(fromDateValue, toDateValue, owner_company_id, statusid, orderno);
		
		
    }
	sortIcon(property: string) {
        if (property === this.sortProperty) {
         
			return this.sortOrder === 1 ? '▲' : '▼';
        }
        return '';
    }

	onValueChange(value: any){
	
		if(value!=''){
		
			const owner_company_id = value;
			const statusid = this.actualDispatchForm.get("statusid").value;
			const orderno = this.actualDispatchForm.get("orderno").value;
			const fromDateValue = this.datePipe.transform(this.actualDispatchForm.get("fromdate").value, "MM-dd-yyyy");
			const toDateValue = this.datePipe.transform(this.actualDispatchForm.get("todate").value, "MM-dd-yyyy");
			this.ActualDispatchPlanGET(fromDateValue, toDateValue, owner_company_id, statusid, orderno);
		
		
		}  
	}
	onStatusChange(value: any){
		
		if(value!=''){
		
			const owner_company_id = this.actualDispatchForm.get("owner_company_id").value;
			const statusid = value;
			const orderno = this.actualDispatchForm.get("orderno").value;
			const fromDateValue = this.datePipe.transform(this.actualDispatchForm.get("fromdate").value, "MM-dd-yyyy");
			const toDateValue = this.datePipe.transform(this.actualDispatchForm.get("todate").value, "MM-dd-yyyy");
			this.ActualDispatchPlanGET(fromDateValue, toDateValue, owner_company_id, statusid, orderno);
		
		
		}  
	}

}
