
import {ChangeDetectorRef,Component,EventEmitter,OnInit,Output} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CoffeeService } from "../../coffee.service";
import { Observable, of } from 'rxjs';

@Component({
  selector: 'kt-category-checkbox-list',
  templateUrl: './category-checkbox-list.component.html',
  styleUrls: ['./category-checkbox-list.component.scss']
})
export class CategoryCheckboxListComponent implements OnInit {

  items$: Observable<any[]>;
  constructor(private fb: FormBuilder,private _service: CoffeeService,private cdRef: ChangeDetectorRef) {}
  @Output() checkedItemsChanged = new EventEmitter<any[]>();
  // @Output() checkboxChange = new EventEmitter<any>();

  checkedItems: { id: any; text: any }[] = [];

  @Output() checkboxChange = new EventEmitter<{ id: any; text: any }[]>();

  ngOnInit(): void {
    const myCategoryIds: { id: any; text: any }[] = [{ id: 'A', text: 'All' }];
    this.loadCategories();
  }

  onCheckboxChange(item: any) {
    item.active = !item.active;
console.log("item>>::",item);
    if (item.active) {
      // Add the item to the checkedItems array
      this.checkedItems.push({ id: item.id, text: item.label });
    } else {
      // Remove the item from the checkedItems array
      this.checkedItems = this.checkedItems.filter(
        (checkedItem) => checkedItem.id !== item.id
      );
    }

    // Emit the updated checkedItems array
    this.checkboxChange.emit(this.checkedItems);

    // this.checkedItemsChanged.emit(item);
    //this.checkboxChange.emit(item);
  }

  activeItem: any = null; // Initialize activeItem to null

  async loadCategories() {
    const delayMilliseconds = 500; // Set the desired delay in milliseconds

    await new Promise((resolve) => setTimeout(resolve, delayMilliseconds));

    const myCategoryIds: { id: any; text: any }[] = [{ id: 'A', text: 'All' }];

    this._service
      .ProductCategoryGetALL(1, 1000, '', '')
      .subscribe((response: any) => {
        this.items$ = of(response);
        this.cdRef.markForCheck();
        this.cdRef.detectChanges();
        console.log("Category>>::",response);
      });
  }

  async loadCategories1() {
    const myCategoryIds: { id: any; text: any }[] = [{ id: 'A', text: 'All' }];

    this._service
      .ProductCategoryGetALL(1, 1000, '', '')
      .subscribe((response: any) => {
        this.items$ = of(response);
      });
  }


}
