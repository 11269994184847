import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { BehaviorSubject, Observable } from "rxjs";
import { CoffeeService } from "../../coffee.service";
import { NgxSpinnerService } from "ngx-spinner";
import { AddOrderComponent } from "../../Orders/add-order/add-order.component";
import { environment } from '../../../../environments/environment';
import { CartService } from "../../cart.service";
import { Router } from "@angular/router";


@Component({
  selector: 'kt-view-product-modal',
  templateUrl: './view-product-modal.component.html',
  styleUrls: ['./view-product-modal.component.scss']
})
export class ViewProductModalComponent {

  
  environment = environment;
  closeResult: string;
  //product: any;
  product: any;
  private poductListSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  private cartSubject = new BehaviorSubject<any[]>([]);
  // @Input() product: any;
  // @Input() public modalConfig: ModalConfig;
  // @ViewChild('content')
  // private modalContent: TemplateRef<ViewProudctModalComponent>;
  // private modalRef: NgbModalRef;
  // environment = environment;
  quantity: number = 1;
  cart: any[] = [];

  constructor(public dialogRef: MatDialogRef<ViewProductModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, private coffeeService: CoffeeService, private spinner: NgxSpinnerService,
  private cartService: CartService,private router: Router) {
		this.product = this.data.product;
    this.quantity=this.data.quantity;
		// this.productList$ = this.poductListSubject.asObservable();

		console.log("ProductData:", this.product," Quantity",this.quantity);
    console.log("ProductName>>:",this.product.ProductName);
	}
  

  getTitle(): string {
		return "View Product";
	}
  generateImageUrl(imageName: string): string {
    return `${this.environment.BaseUrl}/productImages/${imageName}`;
  }

  increaseQuantity() {
    this.quantity++;
  }
 // Function to decrease the quantity
 decreaseQuantity() {
  if (this.quantity > 1) {
    this.quantity--;
  }
}

async addToCartAndClose(product: any) {
  debugger
  // Call the addToCart method of the AddOrderComponent with the specified quantity and cart
  this.addToCartActual(
    product,
    Number.parseInt(this.quantity.toString())
  );
  //await this.close();
 
  await this.dialogRef.close();

  
}

addToCartActual(product: any, quantity: number) {
   
  this.cartService.addToCart(product, quantity);
  this.cartSubject.next(this.cart);

  console.log("Cart>>:",this.cartSubject);
  this.router.navigate(["/coffee/add-order"]);
}

  
  

}
