import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable, Subject, BehaviorSubject } from "rxjs";
import { delay } from "rxjs/operators";

@Injectable({
	providedIn: "root",
})
export class CoffeeService {
	public isLoading = new BehaviorSubject(false);
	constructor(private http: HttpClient) {}

	public own_comp_id:string ="0";
  public own_comp_name:string;

	masterUsersGET(startIndex: number, pageSize: number, searchBy: string, searchCriteria: string,SortColumn: string,SortOrder :string): Observable<any[]> {
		var userdata = JSON.parse(localStorage.getItem("user"));
		console.log(userdata.token);

		const headers = new HttpHeaders({
			Authorization: "Bearer " + userdata.token,
		});

		const params = new HttpParams().set("StartIndex", startIndex.toString()).set("PageSize", pageSize.toString()).set("SearchBy", searchBy).set("SearchCriteria", searchCriteria).set("SortColumn", SortColumn).set("SortOrder", SortOrder);

		return this.http.get<any[]>(environment.BaseUrl + "api/Users/masterUsersGET", {
			headers: headers,
			params: params,
		});
	}

	OwnercompanyGET(startIndex: number, pageSize: number, searchBy: string, searchCriteria: string, SortColumn: string, SortOrder: string): Observable<any[]> {
		var userdata = JSON.parse(localStorage.getItem("user"));
		console.log(userdata.token);

		const headers = new HttpHeaders({
			Authorization: "Bearer " + userdata.token,
		});

		const params = new HttpParams().set("StartIndex", startIndex.toString()).set("PageSize", pageSize.toString()).set("SearchBy", searchBy).set("SearchCriteria", searchCriteria).set("SortColumn", SortColumn).set("SortOrder", SortOrder);
		return this.http.get<any[]>(environment.BaseUrl + "api/OwnserCompany/OwnercompanyGET", {
			headers: headers,
			params: params,
		});
	}

	roleDropdown(): Observable<any[]> {
		var userdata = JSON.parse(localStorage.getItem("user"));
		console.log(userdata.token);

		const headers = new HttpHeaders({
			Authorization: "Bearer " + userdata.token,
		});

		return this.http.get<any[]>(environment.BaseUrl + "api/Users/roleMasterGet", {
			headers: headers,
			params: new HttpParams().set("RoleId", "0"),
		});
	}

	saveUsersDetails(formData) {
		var userdata = JSON.parse(localStorage.getItem("user"));
		const token = userdata.token;
		const headers = new HttpHeaders({
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		});

		return this.http.post<any[]>(environment.BaseUrl + "api/Users/masterUserInsertUpdate", formData, { headers });
	}

	MasterUsersChangePassword(formData) {
		var userdata = JSON.parse(localStorage.getItem("user"));
		const token = userdata.token;
		const headers = new HttpHeaders({
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		});

		return this.http.post<any[]>(environment.BaseUrl + "api/Users/MasterUsersChangePassword", formData, { headers });
	}
	OwnercompanyInsertUpdate(formData) {
		var userdata = JSON.parse(localStorage.getItem("user"));
		const token = userdata.token;
		const headers = new HttpHeaders({
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		});

		return this.http.post<any[]>(environment.BaseUrl + "api/OwnserCompany/ownercompanyInsertUpdate", formData, { headers });
	}

	dropdownAll(searchTerm: string, page: string, type: string, parentId: string): Observable<any[]> {
		var userdata = JSON.parse(localStorage.getItem("user"));
		console.log(userdata.token);

		const headers = new HttpHeaders({
			Authorization: "Bearer " + userdata.token,
		});

		const params = new HttpParams().set("searchTerm", searchTerm.toString()).set("page", page.toString()).set("type", type.toString()).set("parentId", parentId.toString());

		return this.http.get<any[]>(environment.BaseUrl + "api/Owner/allDropdown", {
			headers: headers,
			params: params,
		});
	}

	CategorySave(formData) {
		var userdata = JSON.parse(localStorage.getItem("user"));
		const token = userdata.token;
		const headers = new HttpHeaders({
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		});

		return this.http.post<any[]>(environment.BaseUrl + "api/Category/categorysave", formData, { headers });
	}

	OrdersGET(startIndex: number, pageSize: number, searchBy: string, searchCriteria: string, fromDate: string, toDate: string, owner_company_id: string, statusid: string, orderno: string,SortColumn: string,SortOrder :string): Observable<any[]> {
		var userdata = JSON.parse(localStorage.getItem("user"));
		console.log(userdata.token);

		const headers = new HttpHeaders({
			Authorization: "Bearer " + userdata.token,
		});

		const params = new HttpParams().set("StartIndex", startIndex.toString()).set("PageSize", pageSize.toString()).set("SearchBy", searchBy).set("SearchCriteria", searchCriteria).set("fromDate", fromDate).set("toDate", toDate).set("owner_company_id", owner_company_id).set("statusid", statusid).set("orderno", orderno).set("IsCustomerAccess", "false").set("SortColumn", SortColumn).set("SortOrder", SortOrder);

		return this.http.get<any[]>(environment.BaseUrl + "api/Orders/OrdersGET", {
			headers: headers,
			params: params,
		});
	}

	OrdersGETDispatchPlan(startIndex: number, pageSize: number, searchBy: string, searchCriteria: string, owner_company_id: string): Observable<any[]> {
		var userdata = JSON.parse(localStorage.getItem("user"));
		console.log(userdata.token);

		const headers = new HttpHeaders({
			Authorization: "Bearer " + userdata.token,
		});

		const params = new HttpParams().set("StartIndex", startIndex.toString()).set("PageSize", pageSize.toString()).set("SearchBy", searchBy).set("SearchCriteria", searchCriteria).set("owner_company_id", owner_company_id);

		return this.http.get<any[]>(environment.BaseUrl + "api/Orders/OrdersGETDispatchPlan", {
			headers: headers,
			params: params,
		});
	}

	DispatchPlanGetForEdit(DispatchPlanId: number): Observable<any[]> {
		var userdata = JSON.parse(localStorage.getItem("user"));
		console.log(userdata.token);

		const headers = new HttpHeaders({
			Authorization: "Bearer " + userdata.token,
		});

		const params = new HttpParams().set("DispatchPlanId", DispatchPlanId.toString());

		return this.http.get<any[]>(environment.BaseUrl + "api/DispatchPlan/DispatchPlanGetForEdit", {
			headers: headers,
			params: params,
		});
	}

	OrdersDetailsGET(order_id: number): Observable<any[]> {
		var userdata = JSON.parse(localStorage.getItem("user"));
		console.log(userdata.token);

		const headers = new HttpHeaders({
			Authorization: "Bearer " + userdata.token,
		});

		const params = new HttpParams().set("order_id", order_id.toString());

		return this.http.get<any[]>(environment.BaseUrl + "api/Orders/OrdersDetailsGET", {
			headers: headers,
			params: params,
		});
	}

	OrdersDetailsMultipleOrderIdGET(selectedOrderIds: number[]): Observable<any[]> {
		var userdata = JSON.parse(localStorage.getItem("user"));
		console.log(userdata.token);

		const headers = new HttpHeaders({
			Authorization: "Bearer " + userdata.token,
			"Content-Type": "application/json",
		});

		const requestData = {
			selectedOrderIds: selectedOrderIds,
		};

		return this.http.post<any[]>(environment.BaseUrl + "api/Orders/OrdersDetailsMultipleOrderIdGET", requestData, { headers: headers });
	}

	DispatchPlanInsertUpdate(formData: FormData): Observable<any> {
		var userdata = JSON.parse(localStorage.getItem("user"));
		const token = userdata.token;

		// Create headers
		const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);

		// Send the POST request
		return this.http.post<any[]>(environment.BaseUrl + "api/DispatchPlan/DispatchPlanInsertUpdate", formData, { headers });
	}

	OwnersCompayGetById(owner_company_id: string): Observable<any[]> {
		var userdata = JSON.parse(localStorage.getItem("user"));
		console.log(userdata.token);

		const headers = new HttpHeaders({
			Authorization: "Bearer " + userdata.token,
		});

		const params = new HttpParams().set("owner_company_id", owner_company_id);

		return this.http.get<any[]>(environment.BaseUrl + "api/Owner/OwnersCompayGetById", {
			headers: headers,
			params: params,
		});
	}

	DispatchPlanGET(startIndex: number, pageSize: number, searchBy: string, searchCriteria: string, fromDate: string, toDate: string, owner_company_id: string, statusid: string, orderno: string,sortProperty:string,orderBy:string): Observable<any[]> {
		var userdata = JSON.parse(localStorage.getItem("user"));
		console.log(userdata.token);

		const headers = new HttpHeaders({
			Authorization: "Bearer " + userdata.token,
		});

		const params = new HttpParams().set("StartIndex", startIndex.toString()).set("PageSize", pageSize.toString()).set("SearchBy", searchBy).set("SearchCriteria", searchCriteria).set("fromDate", fromDate).set("toDate", toDate).set("owner_company_id", owner_company_id).set("statusid", statusid).set("orderno", orderno).set("sortProperty", sortProperty).set("orderBy", orderBy);

		return this.http.get<any[]>(environment.BaseUrl + "api/DispatchPlan/DispatchPlanGET", {
			headers: headers,
			params: params,
		});
	}

	DispatchPlanDetailsGET(DispatchPlanId: number): Observable<any[]> {
		var userdata = JSON.parse(localStorage.getItem("user"));
		console.log(userdata.token);

		const headers = new HttpHeaders({
			Authorization: "Bearer " + userdata.token,
		});

		const params = new HttpParams().set("DispatchPlanId", DispatchPlanId.toString());

		return this.http.get<any[]>(environment.BaseUrl + "api/DispatchPlan/DispatchPlanDetailsGET", {
			headers: headers,
			params: params,
		});
	}

	DispatchPlanDetailsBarcodScanner(ImsMappingId: string, owner_company_id: string, serialNo: string): Observable<any[]> {
		var userdata = JSON.parse(localStorage.getItem("user"));
		console.log(userdata.token);

		const headers = new HttpHeaders({
			Authorization: "Bearer " + userdata.token,
		});

		const params = new HttpParams().set("ImsMappingId", ImsMappingId.toString()).set("owner_company_id", owner_company_id.toString()).set("serialNo", serialNo.toString().trim());

		return this.http.get<any[]>(environment.BaseUrl + "api/DispatchPlan/DispatchPlanDetailsBarcodScanner", {
			headers: headers,
			params: params,
		});
	}

	DispatchInsert(formData) {
		var userdata = JSON.parse(localStorage.getItem("user"));
		const token = userdata.token;
		const headers = new HttpHeaders({
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		});

		return this.http.post<any[]>(environment.BaseUrl + "api/DispatchPlan/DispatchInsert", formData, { headers });
	}

	Report_Summary_OrderAgaingstDispatch(startIndex: number, pageSize: number, searchBy: string, searchCriteria: string,fromDate: string,toDate:string,statusid:string,sortProperty:string,orderBy:string): Observable<any[]> {
		var userdata = JSON.parse(localStorage.getItem("user"));
		console.log(userdata.token);

		const headers = new HttpHeaders({
			Authorization: "Bearer " + userdata.token,
		});

		const params = new HttpParams().set("StartIndex", startIndex.toString()).set("PageSize", pageSize.toString()).set("SearchBy", searchBy).set("SearchCriteria", searchCriteria).set("fromDate", fromDate).set("toDate", toDate).set("statusid", statusid).set("sortProperty", sortProperty).set("orderBy", orderBy);

		return this.http.get<any[]>(environment.BaseUrl + "api/Reports/Report_Summary_OrderAgaingstDispatch", {
			headers: headers,
			params: params,
		});
	}

	deleteFromTable(formData) {
		var userdata = JSON.parse(localStorage.getItem("user"));
		const token = userdata.token;
		const headers = new HttpHeaders({
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		});

		return this.http.post<any[]>(environment.BaseUrl + "api/Owner/deleteFromTable", formData, { headers });
	}

	InventoryTransactionsInsert(formData) {
		var userdata = JSON.parse(localStorage.getItem("user"));
		const token = userdata.token;
		const headers = new HttpHeaders({
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		});

		return this.http.post<any[]>(environment.BaseUrl + "api/Products/InventoryTransactionsInsert", formData, { headers });
	}

	ActualDispatchPlanGET(startIndex: number, pageSize: number, searchBy: string, searchCriteria: string, fromDate: string, toDate: string, owner_company_id: string, statusid: string, orderno: string,sortProperty:string,orderBy:string): Observable<any[]> {
		var userdata = JSON.parse(localStorage.getItem("user"));
		console.log(userdata.token);

		const headers = new HttpHeaders({
			Authorization: "Bearer " + userdata.token,
		});
		const params = new HttpParams().set("StartIndex", startIndex.toString()).set("PageSize", pageSize.toString()).set("SearchBy", searchBy).set("SearchCriteria", searchCriteria).set("fromDate", fromDate).set("toDate", toDate).set("owner_company_id", owner_company_id).set("statusid", statusid).set("orderno", orderno).set("sortProperty", sortProperty).set("orderBy", orderBy);

		return this.http.get<any[]>(environment.BaseUrl + "api/DispatchPlan/ActualDispatchPlanGET", {
			headers: headers,
			params: params,
		});
	}

	ActualDispatchPlanDetailsGET(dispatchId: number): Observable<any[]> {
		var userdata = JSON.parse(localStorage.getItem("user"));
		console.log(userdata.token);

		const headers = new HttpHeaders({
			Authorization: "Bearer " + userdata.token,
		});

		const params = new HttpParams().set("dispatchId", dispatchId.toString());

		return this.http.get<any[]>(environment.BaseUrl + "api/DispatchPlan/ActualDispatchPlanDetailsGET", {
			headers: headers,
			params: params,
		});
	}

	PendingOrdersGETCategorywise(startIndex: number, pageSize: number, searchBy: string, searchCriteria: string, fromDate: string, toDate: string, owner_company_id: string, orderno: string,CategoryID:string): Observable<any[]> {
		var userdata = JSON.parse(localStorage.getItem("user"));
		console.log(userdata.token);

		const headers = new HttpHeaders({
			Authorization: "Bearer " + userdata.token,
		});

		const params = new HttpParams().set("StartIndex", startIndex.toString()).set("PageSize", pageSize.toString()).set("SearchBy", searchBy).set("SearchCriteria", searchCriteria).set("fromDate", fromDate).set("toDate", toDate).set("owner_company_id", owner_company_id).set("orderno", orderno).set("CategoryID",CategoryID);

		return this.http.get<any[]>(environment.BaseUrl + "api/Orders/PendingOrdersGETCategorywise", {
			headers: headers,
			params: params,
		});
	}
	CompanyWiseOrdersGET(startIndex: number, pageSize: number, fromDate: string, toDate: string, owner_company_id: string, RegionId: string,StateId:string,CategoryId: string,SortColumn:string,SortOrder:string): Observable<any[]> {
		var userdata = JSON.parse(localStorage.getItem("user"));
		console.log(userdata.token);

		const headers = new HttpHeaders({
			Authorization: "Bearer " + userdata.token,
		});

		const params = new HttpParams().set("StartIndex", startIndex.toString()).set("PageSize", pageSize.toString()).set("fromDate", fromDate).set("toDate", toDate).set("owner_company_id", owner_company_id).set("RegionId", RegionId).set("StateId",StateId).set("CategoryId",CategoryId).set("SortColumn",SortColumn).set("SortOrder",SortOrder);

		return this.http.get<any[]>(environment.BaseUrl + "api/Orders/CompanyWiseOrdersGET", {
			headers: headers,
			params: params,
		});
	}
	PendingOrderValueGet(fromDate: string, toDate: string, owner_company_id: string, CategoryID: string): Observable<any[]> {
		var userdata = JSON.parse(localStorage.getItem("user"));
		console.log(userdata.token);

		const headers = new HttpHeaders({
			Authorization: "Bearer " + userdata.token,
		});

		const params = new HttpParams().set("fromDate", fromDate).set("toDate", toDate).set("owner_company_id", owner_company_id).set("CategoryID", CategoryID);

		return this.http.get<any[]>(environment.BaseUrl + "api/Orders/PendingOrderValueGet", {
			headers: headers,
			params: params,
		});
	}

	productsGET(startIndex: number,pageSize: number,searchBy: string,searchCriteria: string,categroyid: any[]): Observable<any[]> {
		var userdata = JSON.parse(localStorage.getItem("user"));
		const headers = new HttpHeaders({
			Authorization: "Bearer " + userdata.token,
		});
	
		const params = new HttpParams().set('StartIndex', startIndex.toString()).set('PageSize', pageSize.toString())
		  .set('SearchBy', searchBy).set('SearchCriteria', searchCriteria)
		  .set('tableDataJsons', JSON.stringify(categroyid));
		return this.http.get<any[]>(
		  environment.BaseUrl + 'api/Products/ProductsGETCusomters',
		  {
			headers: headers,
			params: params,
		  }
		);
	  }

	  ProductCategoryGetALL(startIndex: number,pageSize: number,searchBy: string,searchCriteria: string): Observable<any[]> {
		var userdata = JSON.parse(localStorage.getItem("user"));
		const headers = new HttpHeaders({
			Authorization: "Bearer " + userdata.token,
		});
	
		const params = new HttpParams()
		  .set('StartIndex', startIndex.toString())
		  .set('PageSize', pageSize.toString())
		  .set('SearchBy', searchBy)
		  .set('SearchCriteria', searchCriteria);
		return this.http.get<any[]>(
		  environment.BaseUrl + 'api/Products/ProductCategoryGetALL',
		  {
			headers: headers,
			params: params,
		  }
		);
	  }
	
	  placeOrder(cartItems: any): Observable<any[]> {
		var userdata = JSON.parse(localStorage.getItem("user"));
		const headers = new HttpHeaders({
			Authorization: "Bearer " + userdata.token,
		});
	
		return this.http.post<any[]>(
		  environment.BaseUrl + 'api/Orders/PlaceOrder',
		  cartItems,
		  { headers }
		);
	  }

	  productsGETOrder(startIndex: number,pageSize: number,searchBy: string,searchCriteria: string,categroyid: any[],owner_company_id: string): Observable<any[]> {
		var userdata = JSON.parse(localStorage.getItem("user"));
		const headers = new HttpHeaders({
			Authorization: "Bearer " + userdata.token,
		});
	
		const params = new HttpParams().set('StartIndex', startIndex.toString()).set('PageSize', pageSize.toString())
		  .set('SearchBy', searchBy).set('SearchCriteria', searchCriteria)
		  .set('tableDataJsons', JSON.stringify(categroyid)).set("owner_company_id", owner_company_id);
		return this.http.get<any[]>(
		  environment.BaseUrl + 'api/Products/ProductsGETOrder',
		  {
			headers: headers,
			params: params,
		  }
		);
	  }

	  UsersInRegionsGET(RegionId: string): Observable<any[]> {
		var userdata = JSON.parse(localStorage.getItem("user"));
		const headers = new HttpHeaders({
			Authorization: "Bearer " + userdata.token,
		});
	
		const params = new HttpParams().set('RegionID', RegionId.toString());
		return this.http.get<any[]>(
		  environment.BaseUrl + 'api/Users/UsersInRegionsGET',
		  {
			headers: headers,
			params: params,
		  }
		);
	  }

	  UsersInRegionsInsertUpdate(formData){ 
		var userdata = JSON.parse(localStorage.getItem("user"));
		const headers = new HttpHeaders({
			Authorization: "Bearer " + userdata.token,
		});
	
		return this.http.post<any[]>(environment.BaseUrl + "api/Users/UsersInRegionsInsertUpdate", formData, { headers });
		
	  }
	  DispatchPlanDetailsGETforEmail(dispatchId:string){ debugger
		var userdata = JSON.parse(localStorage.getItem("user"));
		const headers = new HttpHeaders({
			Authorization: "Bearer " + userdata.token,
		});
		const params = new HttpParams().set('dispatchId', dispatchId.toString());
	
		//return this.http.post<any[]>(environment.BaseUrl + "api/Products/DispatchPlanDetailsGETforEmail", dispatchId, { headers });
		return this.http.get<any[]>(
			environment.BaseUrl + 'api/DispatchPlan/DispatchPlanDetailsGETforEmail',
			{
			  headers: headers,
			  params: params,
			}
		  );

		  
	  }

	  DispatchProductSerialNoGET(startIndex: number, pageSize: number, StartSerialNo: string, EndSerialNo: string,owner_company_id:string, CategoryId: string,ProductId:string,SortColumn:string,SortOrder:string): Observable<any[]> {
		  var userdata = JSON.parse(localStorage.getItem("user"));
		  console.log(userdata.token);
  
		  const headers = new HttpHeaders({
			  Authorization: "Bearer " + userdata.token,
		  });
  
		  const params = new HttpParams().set("StartIndex", startIndex.toString()).set("PageSize", pageSize.toString()).set("StartSerialNo", StartSerialNo).set("EndSerialNo", EndSerialNo).set("owner_company_id", owner_company_id).set("CategoryId", CategoryId).set("ProductId", ProductId).set("SortColumn", SortColumn).set("SortOrder", SortOrder);
		  return this.http.get<any[]>(environment.BaseUrl + "api/DispatchPlan/DispatchProductSerialNoGET", {
			  headers: headers,
			  params: params,
		  });
	  }

	  OrdersDetailsCancelUpdate(updateStatus: any): Observable<any[]> {
		var userdata = JSON.parse(localStorage.getItem("user"));
		const headers = new HttpHeaders({
			Authorization: "Bearer " + userdata.token,
		});
	
		return this.http.post<any[]>(
		  environment.BaseUrl + 'api/Orders/OrdersDetailsCancelUpdate',
		  updateStatus,
		  { headers }
		);
	  }

	
}
