import { Component, Inject, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { CoffeeService } from '../../coffee.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from "@angular/router";

@Component({
  selector: 'kt-change-user-password',
  templateUrl: './change-user-password.component.html',
  styleUrls: ['./change-user-password.component.scss']
})
export class ChangeUserPasswordComponent implements OnInit {
  userPasswordForm: FormGroup;
  loading = false;
  private subscription: Subscription;
  userId = "0";


  constructor(public dialogRef: MatDialogRef<ChangeUserPasswordComponent>, @Inject(MAT_DIALOG_DATA) public data: any,private _router: Router, private fb: FormBuilder, private coffeeService: CoffeeService, private spinner: NgxSpinnerService,private _snackBar: MatSnackBar) { 
    const UserId = this.data.UserId;
   
  this.userId=UserId;


  }

  ngOnInit() {

    this.userPasswordForm = this.fb.group(
			{ userId: ["0"], 
        passwords: ["", [Validators.required,this.passwordValidator]], 
        confirmpassword: ["", [Validators.required]] 
      },
			{
				validator: this.passwordMatchValidator,
        
			}
		);

    this.userPasswordForm.controls.userId.setValue(this.userId.toString());

  }

  passwordMatchValidator(control: AbstractControl): { [key: string]: boolean } | null {
		const password = control.get("passwords").value;
		const confirmPassword = control.get("confirmpassword").value;

		// check if passwords match
		if (password !== confirmPassword) {
			return { passwordMismatch: true };
		}

		return null; // validation passed
	}

  passwordValidator(control: AbstractControl): { [key: string]: any } | null {

    const passwordPattern =/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{8,}/;
    const password = control.value;


    if (password && !passwordPattern.test(password)) {
      return { invalidPassword: true };
    }
  
    return null;
  }


  onChangePassword() {
		this.loading = true;
		if (this.userPasswordForm.invalid) {
			Object.values(this.userPasswordForm.controls).forEach((control) => control.markAsTouched());
      this.loading = false;
			return;
		}

		const formData = { ...this.userPasswordForm.value };
		this.coffeeService.MasterUsersChangePassword(formData).subscribe(
			(res: any) => {
				if (res.error === false) {
					this._snackBar.open("Error occurred while saving", "OK", {
						duration: 3000,
					});
          this.loading = false;
				} else {
					this._snackBar.open("Change Password Successfully", "Undo", {
						duration: 3000,
					});
          this.loading = false;
				//	this._router.navigate(["/dashboard"]);
				}
			},
			(error: any) => {
				this._snackBar.open(error.error, "OK", {
					duration: 3000,
				});
				this.loading = false;
			}
		);
	}






}
