import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from "@angular/material";
import { BehaviorSubject, Observable } from "rxjs";
import { CoffeeService } from "../../coffee.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ProductsService } from "../../products/product.service";
import { take } from "rxjs/operators";
import Swal from 'sweetalert2';


@Component({
	selector: "kt-edit-pricing",
	templateUrl: "./edit-pricing.component.html",
	styleUrls: ["./edit-pricing.component.scss"],
})
export class EditPricingComponent implements OnInit {
	customerForm: FormGroup;
	hasFormErrors = false;
	viewLoading = false;
	loading = true;
	ordersList$: Observable<any[]>;
	private ordersListSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

	order_no: String;
	order_date: String;
	order_id: String;
	orderValue: String;
	company_name: String;
	order_status: String;
	ProductsPricingId: String;
	PricingName:String;
	selectedProductDataList: any[] = [];

	constructor(public dialogRef: MatDialogRef<EditPricingComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, private coffeeService: CoffeeService, private _service: ProductsService, private spinner: NgxSpinnerService, private _snackBar: MatSnackBar) {
		const ProductsPricingId = this.data.ProductsPricingId;
		const PricingName = this.data.PricingName;
		this.ordersList$ = this.ordersListSubject.asObservable();
		this.ProductsPricingId = ProductsPricingId.toString();
		this.PricingName=PricingName.toString();
		this.OrdersDetailsGET(ProductsPricingId);
		console.log("Received Order ID:", ProductsPricingId);
	}

	OrdersDetailsGET(ProductsPricingId) {
		this.spinner.show();
		this._service.Products_pricing_detailsGET(ProductsPricingId).subscribe((response: any[]) => {
			this.ordersListSubject.next(response);
			console.log(response);
			this.spinner.hide();
		});
	}

	ngOnInit() {
		this.createForm();
	}

	createForm() {
		this.customerForm = this.fb.group({
			firstName: [" ", Validators.required],
			lastName: ["", Validators.required],
			email: ["", Validators.compose([Validators.required, Validators.email])],
			dob: ["", Validators.compose([Validators.nullValidator])],
			userName: ["", Validators.compose([Validators.required])],
			gender: ["", Validators.compose([Validators.required])],
			ipAddress: ["", Validators.compose([Validators.required])],
			type: ["", Validators.compose([Validators.required])],
		});
	}
	getTitle(): string {
		return "Edit Product Pricing";
	}

	isInvalidPrice(product: any): boolean {
		return product.Price === null || product.Price === undefined || product.Price === "" || !/^\d+$/.test(product.Price) || product.Price.length > 6;
	}
	saveTableData() {
		this.ordersList$.pipe(take(1)).subscribe((usersList) => {
			// Filter out products with invalid prices
			const validProducts = usersList.filter((product) => !this.isInvalidPrice(product));

			const dataToSave = {
				ProductsPricingId: this.ProductsPricingId,
				productpricingname: this.PricingName,
				products: validProducts.map((product) => ({
					id: product.ProductsId,
					price: product.Price.toString(),
				})),
			};
			debugger;
			console.log(dataToSave);
			// Assuming you have a service or API call to save the table data
			// Replace 'saveTableDataService' with your actual service or API call
			this._service.ProductsPriceSave(dataToSave).subscribe(
				(response) => {
					// Handle the response if needed
					console.log("Table data saved successfully!");
					this._snackBar.open("Saved Successfully", "Undo", {
						duration: 3000,
					});
				},
				(error) => {
					// Handle the error if needed
					console.error("Failed to save table data:", error);

					this._snackBar.open("Saved Successfully", "Undo", {
						duration: 3000,
					});
				}
			);
		});
	}

	onProductDeleteChange(item:any,value:any){
		console.log("Checked::",value.target.checked);
		console.log("item::",item);
		if(value.target.checked==true){
			this.selectedProductDataList.push(item);
		  }
		  else{
			  const index = this.selectedProductDataList.findIndex(p=>p.ProductId==item.ProductId);
			  this.selectedProductDataList.splice(index,1);
		  }
		  
	  
		  console.log("SelectedProductData::",this.selectedProductDataList);
		  
	}
	deleteProductTable(){
	
		debugger
		const selectedProducts = this.selectedProductDataList;
		console.log("selectedProducts::", selectedProducts)
		if(selectedProducts.length>0){
			const productsToDelete = {
				ProductsPricingId: this.ProductsPricingId,
				productpricingname: "0",
				products: selectedProducts.map((product) => ({
					id: product.ProductsId,
					price: product.Price.toString(),
				})),
				
			};

			console.log("productsToDelete:::",productsToDelete);
			this._service.ProductPricingDetailDelete(productsToDelete).subscribe(
				(response) => {
					// Handle the response if needed
					console.log("Table data saved successfully!");
					this._snackBar.open("Saved Successfully", "Undo", {
						duration: 3000,
					});
				},
				(error) => {
					// Handle the error if needed
					console.error("Failed to save table data:", error);

					this._snackBar.open("Saved Successfully", "Undo", {
						duration: 3000,
					});
				}
			);
			this.dialogRef.close();
			
		}
		else{
			Swal.fire('Error', 'Please Select Product', 'error');
		}

		
	}
}
