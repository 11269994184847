import { Component, OnInit, ViewChild, AfterViewInit, Input, Output, EventEmitter, ChangeDetectorRef } from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { FormBuilder, FormGroup, Validators, FormArray, FormGroupName } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { Event, NavigationEnd, NavigationStart, NavigationCancel, NavigationError, Router, NavigationExtras } from "@angular/router";
import { BaseDataSource, QueryParamsModel, LayoutUtilsService, MessageType } from "../../core/_base/crud";
import { NgxSpinnerService } from "ngx-spinner";
import { MatDialogConfig, MatSort, MatSnackBar, MatDialog, MatDialogRef } from "@angular/material";
import { Action, Store } from "@ngrx/store";
import { AppState } from "../../core/reducers";
import { MasterModel } from "../../views/pages/masters/masters.model";
import { Observable, Subject, BehaviorSubject } from "rxjs";
import { CoffeeService } from "../coffee.service";
import { SharedDataService } from "../shared-data.service";
import { ConfirmationModalComponent } from "../Modals/confirmation-modal/confirmation-modal.component";
const MASTER_DATA: MasterModel[] = [];

@Component({
	selector: "kt-usersdata",
	templateUrl: "./usersdata.component.html",
	styleUrls: ["./usersdata.component.scss"],
})
export class UsersdataComponent implements OnInit {
	displayedColumns: string[] = ["MasterName", "SubMasterName", "actions"];
	dataSource = new MatTableDataSource<MasterModel>(MASTER_DATA);
	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;
	public master: MasterModel;
	showSpinner = false;
	public hasItems: boolean;
	totalLength: number = 119;
	pageIndex: number = 1;
	pageSize: number = 10;
	MasterId: number = 0;
	public employeeId: string = "0";
	totalLengthEmitter$ = new BehaviorSubject<number>(this.totalLength);

	masters: [];

	observable: Observable<[]>;
	loading2 = false;
	submitted = false;
	applyLeaveId: number = 0;
	masterList$: Observable<any>;
	tempmasterList$: Observable<any>;
	masterDropDown$: Observable<any>;
	empstatusfrm: FormGroup;
	employeeStatusFormShow = false;
	@Output() messageEvent = new EventEmitter<string>();

	usersList$: Observable<any[]>;
	private usersListSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

	//Pagination
	totalPages: number = 50;
	currentPage: number = 1;
	totalRecords: number = 0;
	itemsPerPage = 10;
	totalItems = 0;
	searchQuery: string = "";


	// Sorting Colum in Asc and Desc
    SortColumn: string = 'UserID';
    sortOrderValue = 1;
    SortOrder:string="DESC";


	constructor(private formBuilder: FormBuilder, private sharedDataService: SharedDataService, private _snackBar: MatSnackBar, private spinner: NgxSpinnerService, private _router: Router, private dialog: MatDialog, private store: Store<AppState>, public snackBar: MatSnackBar, private layoutUtilsService: LayoutUtilsService, private ref: ChangeDetectorRef, private coffeeService: CoffeeService) {
		this.usersList$ = this.usersListSubject.asObservable();
	}

	ngOnInit() {
		this.getUsers();
	}
	editUser(user: any) {
		// let navigationExtras: NavigationExtras = {
		// 	queryParams: {
		// 		user: JSON.stringify(user),
		// 	},
		// };
		// this._router.navigate(["/coffee/edit-user"], navigationExtras);

		this.sharedDataService.setUserData(JSON.stringify(user));
		this._router.navigate(["/coffee/edit-user"]);
	}

	InActiveUser(UserId) {
		const dialogRef = this.dialog.open(ConfirmationModalComponent, {
			data: {
				title: "Confirmation",
				description: "Are you sure you want to Inactive user ?",
				waitDescription: "Please wait we are submitting the data",
			},
			width: "440px",
		});
		dialogRef.afterClosed().subscribe((res) => {
			if (!res) {
				return;
			}
			this.DeleteUser(UserId);
		});
	}

	DeleteUser(UserId) {
		const formData = {
			TypeId: "1",
			Id: UserId.toString(),
		};

		this.coffeeService.deleteFromTable(formData).subscribe(
			(res: any) => {
				if (res.error === false) {
					this._snackBar.open("Error occurred while saving", "OK", {
						duration: 3000,
					});
				} else {
					this._snackBar.open("deleted Successfully", "Undo", {
						duration: 3000,
					});
					this.getUsers();
				}
			},
			(error: any) => {
				this._snackBar.open(error.error, "OK", {
					duration: 3000,
				});
			}
		);
	}

	getUsers() {
		const startIndex = this.currentPage;
		const pageSize = this.itemsPerPage;
		const searchBy = this.searchQuery ? "1" : "0";
		const searchCriteria = this.searchQuery;
		this.spinner.show();
		const SortColumn = this.SortColumn;
		const SortOrder=this.SortOrder;


		this.coffeeService.masterUsersGET(startIndex, pageSize, searchBy, searchCriteria,SortColumn,SortOrder).subscribe((response: any[]) => {
			this.usersListSubject.next(response);
			this.totalPages = response[0].TotalPages;
			this.totalRecords = response[0].TotalRecords;
			this.spinner.hide();
		});
	}
	onPageChanged(e) {
		this.pageIndex = e.pageIndex + 1;
		this.pageSize = e.pageSize;
		this.getUsers();
	}
	onPageSizeChange(newSize: number) {
		this.itemsPerPage = newSize;
		this.getUsers();
		this.ref.detectChanges();
	}
	editUserData(userInfo: string) {
		localStorage.setItem("usersStorage", JSON.stringify(userInfo));
		this._router.navigate(["/coffee/usermaster"]);
	}

	//Pagination

	onPageChange(page) {
		this.currentPage = page;
		this.getUsers();
	}
	onSearch(query: string) {
		this.searchQuery = query;
		this.currentPage = 1;
		// this.searchQuery = "1";

		this.getUsers();
	}

//  sorting function call
sortBy(property: string) {
	this.sortOrderValue = property === this.SortColumn ? (this.sortOrderValue * -1) : 1;
	this.SortColumn = property;
   
	if(this.sortOrderValue ===1)
		{
			this.SortOrder="ASC";		

		}
	if(this.sortOrderValue ===-1)
		{
			this.SortOrder="DESC";				

		}
	this.getUsers();
	
}
sortIcon(property: string) {
	if (property === this.SortColumn) {
	 
		return this.sortOrderValue === 1 ? '▲' : '▼';
	}
	return '';
}


}
