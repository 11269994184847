import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { BehaviorSubject, Observable } from "rxjs";
import { CoffeeService } from "../../coffee.service";
import { NgxSpinnerService } from "ngx-spinner";
import JsBarcode from "jsbarcode";
@Component({
	selector: "kt-view-dispatch",
	templateUrl: "./view-dispatch.component.html",
	styleUrls: ["./view-dispatch.component.scss"],
})
export class ViewDispatchComponent implements OnInit {
	customerForm: FormGroup;
	hasFormErrors = false;
	viewLoading = false;
	loading = false;
	ordersList$: Observable<any[]>;
	private ordersListSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

	order_no: String;
	order_date: String;
	DispatchPlanId: String;
	orderValue: String;
	company_name: String;
	order_status: String;
	barcodes: string[] = [];

	px2mmFactor: number;

	constructor(public dialogRef: MatDialogRef<ViewDispatchComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, private coffeeService: CoffeeService, private spinner: NgxSpinnerService) {
		const DispatchPlanId = this.data.DispatchPlanId;
		this.ordersList$ = this.ordersListSubject.asObservable();

		this.OrdersDetailsGET(DispatchPlanId);
		console.log("Received Order ID:", DispatchPlanId);
	}

	OrdersDetailsGET(DispatchPlanId) {
		this.spinner.show();
		this.coffeeService.DispatchPlanDetailsGET(DispatchPlanId).subscribe((response: any[]) => {
			this.ordersListSubject.next(response);
			this.spinner.hide();
			this.orderValue = response[0].order_value;
			this.DispatchPlanId = response[0].DispatchPlanId;
			this.order_date = response[0].DispatchPlanDate;
			this.company_name = response[0].company_name;
			this.order_status = response[0].order_status;
		});

		this.ordersList$.subscribe((orders) => {
			this.barcodes = orders.map((order) => this.generateBarcode(order.order_no + "." + order.DispatchPlanDetailsId + "." + order.PlanQty));
		});
	}

	ngOnInit() {
		this.createForm();

		this.px2mmFactor = this.calcPx2MmFactor();

		let data: string = "230220119012";

		JsBarcode("#barcode", data, {
			format: "code128", // default
			height: 10 * this.px2mmFactor, // 10mm
			width: 2.3,
			// displayValue: false,
			text: "-" + data + "-",
			background: "rgba(0,0,0,0.1)",
			font: "monospace",
			fontOptions: "bold",
			fontSize: 16,
			lineColor: "darkblue",
			margin: 5 * this.px2mmFactor, // 5mm
			textMargin: 2 * this.px2mmFactor, // 2mm
			// textAlign: 'right',
			// textPosition: 'top',
		});
	}

	private calcPx2MmFactor() {
		let e = document.createElement("div");
		e.style.position = "absolute";
		e.style.width = "100mm";
		document.body.appendChild(e);
		let rect = e.getBoundingClientRect();
		document.body.removeChild(e);
		return rect.width / 100;
	}

	generateBarcode(data: string): string {
		const barcodeCanvas = document.createElement("canvas");
		JsBarcode(barcodeCanvas, data, {
			format: "CODE128", // Change to your desired barcode format
			displayValue: true,
		});
		return barcodeCanvas.toDataURL("image/png");
	}

	createForm() {
		this.customerForm = this.fb.group({
			firstName: [" ", Validators.required],
			lastName: ["", Validators.required],
			email: ["", Validators.compose([Validators.required, Validators.email])],
			dob: ["", Validators.compose([Validators.nullValidator])],
			userName: ["", Validators.compose([Validators.required])],
			gender: ["", Validators.compose([Validators.required])],
			ipAddress: ["", Validators.compose([Validators.required])],
			type: ["", Validators.compose([Validators.required])],
		});
	}
	getTitle(): string {
		return "View Dispatch Plan";
	}

	print1(): void {
		const printContent = document.querySelector(".print-view");
		const windowPopup = window.open("", "_blank");
		windowPopup.document.open();
		windowPopup.document.write(`
		  <html>
			<head>
			  <title>Print View</title>
			  <link rel="stylesheet" type="text/css" href="print-styles.css">
			</head>
			<body onload="window.print(); window.close();">
			  ${printContent.innerHTML}
			</body>
		  </html>
		`);
		windowPopup.document.close();
	}

	print():void{
		
		window.print();
	}

	
}
