import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { CartService } from '../../cart.service';
import { environment } from '../../../../../src/environments/environment';
import { CoffeeService } from "../../coffee.service";
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import * as $ from 'jquery';
import { Event } from 'jquery';

@Component({
  selector: 'kt-place-order',
  templateUrl: './place-order.component.html',
  styleUrls: ['./place-order.component.scss']
})
export class PlaceOrderComponent implements OnInit {

  cartItems: any[] = [];
  environment = environment;

  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLoading: boolean;
  private unsubscribe: Subscription[] = [];
  stateDropdown$: Observable<any>;
  quantity: number = 1;
  stateData: any[] = [];
  stateOptions: any = {};
  stateOptionsConfig: any = {};
  comment: string;

  owner_company_name: string = '';
  dropdownOptions = [
    'Option 1',
    'Option 2',
    'Option 3',
    // Add more options as needed
  ];
  selectedOption: string;

  constructor(private cartService: CartService,private _service: CoffeeService,private cdr: ChangeDetectorRef) {
    const loadingSubscr = this.isLoading$
      .asObservable()
      .subscribe((res) => (this.isLoading = res));
    this.unsubscribe.push(loadingSubscr);
  }

  statedropdown() {
    this.stateDropdown$ = this._service.dropdownAll('', '1', '2', '0');
  }
  placeOrder() {

    if(this._service.own_comp_id=="0" || this._service.own_comp_id==null)
      {
        Swal.fire('Error', 'Please Select Company', 'error');
      }
      else if(this.cartItems.length==0){
        Swal.fire('Error', 'Please Select Products', 'error');
      }
      else if(this.comment=="" || this.comment==null){
        Swal.fire('Error', 'Please Enter comment', 'error');
      }
      else{
        this.isLoading$.next(true);

        const orderData = {
          cartItems: this.cartItems,
          comment: this.comment, // Add comment to order data
          owner_company_id:this._service.own_comp_id,
        };
    
        this._service.placeOrder(orderData).subscribe(
          (res: any) => {
            this.cartService.clearCart();
            this.isLoading$.next(false);
            this.cdr.detectChanges();
            Swal.fire(
              'Order Placed Successfully !',
              'Your order placed successfully!',
              'success'
            );
          },
          (error: any) => {
            this.isLoading$.next(false);
            Swal.fire('Error', error.error.message, 'error');
          }
        );
      }

    
  }
  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  ngOnInit(): void {
    this.cartService.cart$.subscribe((cartItems) => {
      this.cartItems = cartItems;
    });
    console.log(jQuery);
    console.log("CartItems::",this.cartItems);
    this.statedropdown();
this.owner_company_name=this._service.own_comp_name;

   // console.log("Own Name>>::",this.owner_company_name)
  
   var userdata = JSON.parse(localStorage.getItem("user"));
		
    this.stateOptionsConfig = {
      ajax: {
        url: environment.BaseUrl + 'api/Owner/allDropdown',
        dataType: 'json',
        delay: 250,
        headers: {
          Authorization: 'Bearer ' + + userdata.token,
        },
        data: (params: any) => {
          return {
            searchTerm: params.term,
            page: params.page,
            type: 2,
            parentId: 0,
          };
        },
        processResults: (data: any) => {
          const results = data.map((item: any) => {
            return {
              id: item.id,
              text: item.text,
            };
          });

          return {
            results: results,
          };
        },
        cache: true,
      },
      placeholder: 'Select a State',
      minimumInputLength: 0,
    };
  }

// Calculate the total price of cart items
getTotalCartPrice(): number {
  return this.cartItems.reduce(
    (total, item) => total + item.price * item.count,
    0
  );
}
getItemCount(productId: number): number {
  this.quantity = this.cartService.getItemCount(productId);
  // console.log("CarItems:>>",this.cartItems);
  // console.log("productId:>>",productId);
  // console.log("getItemCount:>>",this.quantity);
    return this.quantity;
}
addToCartActual(product: any, quantity: number) {
  this.cartService.addToCart(product, quantity);
}
increaseCartItem(item: any) {
  const newQuantity = 1;
  this.addToCartActual(item, newQuantity);
}
removeFromCart(item: any) {
  this.cartService.removeFromCart(item);
}
decreaseCartItem(item: any) {
  this.removeFromCart(item);
}
generateImageUrl(imageName: string): string {
  return `${this.environment.BaseUrl}/productImages/${imageName}`;
}

// Function to handle input change
onQuantityChange(item: any) {
  // Update the quantity of the item in the cart
  const parsedQuantity = this.quantity;
  if (!isNaN(parsedQuantity) && parsedQuantity >= 0) {
    if (parsedQuantity === 0) {
      // If the quantity is 0, remove the item from the cart
      this.cartService.removeFromCartItem(item);
    } else {
      // Otherwise, update the quantity using the cart service
      this.cartService.updateCartItemQuantity(item.ProductId, parsedQuantity);
    }
  }
}


updateCartItemQuantity(item: any, newQuantity: string) {
  // Ensure the newQuantity is a valid number
  
  const parsedQuantity = parseInt(newQuantity, 10);
  if (!isNaN(parsedQuantity) && parsedQuantity >= 0) {
    if (parsedQuantity === 0) {
      // If the quantity is 0, remove the item from the cart
      this.cartService.removeFromCartItem(item);
    } else {
      // Otherwise, update the quantity using the cart service
      this.cartService.updateCartItemQuantity(item.ProductId, parsedQuantity);
     
    }
  }
}

removeProduct(item: any){

  this.cartService.removeFromCartItem(item);
  
  }



}
