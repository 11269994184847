import { ChangeDetectorRef, AfterViewInit, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MatSnackBar } from "@angular/material";
import { BehaviorSubject, Observable } from "rxjs";
import { CoffeeService } from "../../coffee.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ViewOrderComponent } from "../../Modals/view-order/view-order.component";
import { environment } from "../../../../../src/environments/environment";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DatePipe } from "@angular/common";
import{NgSelect2Component,NgSelect2Module,Select2OptionData} from "ng-select2";

@Component({
	selector: "kt-rpt-order-dispatch",
	templateUrl: "./rpt-order-dispatch.component.html",
	styleUrls: ["./rpt-order-dispatch.component.scss"],
})
export class RptOrderDispatchComponent implements OnInit {
	totalLength: number = 119;
	pageIndex: number = 1;
	pageSize: number = 10;
	
	//Pagination
	totalPages: number = 50;
	currentPage: number = 1;
	totalRecords: number = 0;
	itemsPerPage = 10;
	totalItems = 0;
	searchQuery: string = "";
	loading = false;
	
	// New Check Sorting
	sortProperty: string = 'order_no';
    sortOrder = 1;
	orderBy:string="DESC";

	ordersList$: Observable<any[]>;

	statusData: any[] = [];
	statusOptions: any = {};
	statusOptionsConfig: any = {};
	reportForm: FormGroup;
	loadingSubmit = false;
	private ordersListSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
	constructor(private dialog: MatDialog, private fb: FormBuilder,private datePipe: DatePipe, public snackBar: MatSnackBar, private ref: ChangeDetectorRef, private coffeeService: CoffeeService, private spinner: NgxSpinnerService) {
		this.ordersList$ = this.ordersListSubject.asObservable();

		var userdata = JSON.parse(localStorage.getItem("user"));

		this.statusOptionsConfig = {
			ajax: {
				url: environment.BaseUrl + "api/Owner/allDropdown",
				dataType: "json",
				delay: 250,
				headers: {
					Authorization: "Bearer " + userdata.token,
				},
				data: (params: any) => {
					return {
						searchTerm: params.term,
						page: params.page,
						type: 9,
						parentId: 0,
					};
				},
				processResults: (data: any) => {
					const results = data.map((item: any) => {
						return {
							id: item.id,
							text: item.text,
						};
					});
					results.splice(0,0,{id: 0,text: "Show All"});
					return {
						results: results,
					};
				},
				cache: true,
			},
			placeholder: "Select a Status",
			minimumInputLength: 0,
		};
	}
	

	openModal(orderId: number) {
		const dialogRef = this.dialog.open(ViewOrderComponent, {
			data: { orderId: orderId },
		});

		// You can handle dialog events here if needed
		dialogRef.afterClosed().subscribe((result) => {
			console.log("Dialog closed with result:", result);
		});
	}

	ngOnInit() {
		this.reportForm = this.fb.group({
			siteid: ["", Validators.compose([Validators.required])],

			fromdate: ["", Validators.compose([Validators.required])],

			todate: ["", Validators.compose([Validators.required])],
		});

		this.Report_Summary_OrderAgaingstDispatch(null,null,null);
	}
	statusChanged(event:any) {
		console.log("Status Changed:", event);
		const statusid = this.reportForm.get("siteid").value;
		const fromDateValue = this.datePipe.transform(this.reportForm.get("fromdate").value, "MM-dd-yyyy");
		const toDateValue = this.datePipe.transform(this.reportForm.get("todate").value, "MM-dd-yyyy");

		this.Report_Summary_OrderAgaingstDispatch(fromDateValue,toDateValue,event);
	}

	Report_Summary_OrderAgaingstDispatch(fromDate: string, toDate: string,statusid: string) {
		const startIndex = this.pageIndex; // Set your desired values for the parameters
		const pageSize = this.pageSize;
		const searchBy = "";
		const searchCriteria = "";
		this.spinner.show();
		this.loadingSubmit = true;

		const sortProperty = this.sortProperty;
		const orderBy=this.orderBy;
	

		console.log("statusidService=",statusid+", Fromdate=",fromDate +", Todate=",toDate);

		this.coffeeService.Report_Summary_OrderAgaingstDispatch(startIndex, pageSize, searchBy, searchCriteria,fromDate,toDate,statusid,sortProperty,orderBy).subscribe((response: any[]) => {
			this.ordersListSubject.next(response);
			this.loadingSubmit = false;
			this.totalPages = response[0].TotalPages;
			this.totalRecords = response[0].TotalRecords;
			this.totalLength =response[0].TotalRecords;
			this.spinner.hide();
			this.loadingSubmit = false;
			
		});
	}

	onSubmit(){
		if (this.reportForm.invalid) {
			Object.values(this.reportForm.controls).forEach((control) => control.markAsTouched());
			return;
		}
		const statusid = this.reportForm.get("siteid").value;
		const fromDateValue = this.datePipe.transform(this.reportForm.get("fromdate").value, "MM-dd-yyyy");
		const toDateValue = this.datePipe.transform(this.reportForm.get("todate").value, "MM-dd-yyyy");

		console.log("statusid=",statusid+", Fromdate=",fromDateValue +", Todate=",toDateValue);
		this.Report_Summary_OrderAgaingstDispatch(fromDateValue,toDateValue,statusid);
	}
	resetform() {
		this.reportForm.reset();
		this.Report_Summary_OrderAgaingstDispatch(null,null,null);
	}
	onPageChanged(e) {
		console.log("pageIndex>>::",this.pageIndex+"pageSize>>:",this.pageSize);
		this.pageIndex = e.pageIndex + 1;
		this.pageSize = e.pageSize;
		console.log("pageIndex>>::",this.pageIndex+"pageSize>>:",this.pageSize);
		const statusid = this.reportForm.get("siteid").value;
		const fromDateValue = this.datePipe.transform(this.reportForm.get("fromdate").value, "MM-dd-yyyy");
		const toDateValue = this.datePipe.transform(this.reportForm.get("todate").value, "MM-dd-yyyy");

		console.log("statusid=",statusid+", Fromdate=",fromDateValue +", Todate=",toDateValue);
		this.Report_Summary_OrderAgaingstDispatch(fromDateValue,toDateValue,statusid);
	}
	onPageChange(page) {
		this.currentPage = page;		
		this.pageIndex = page;
		//this.pageSize = page.pageSize;		
		const statusid = this.reportForm.get("siteid").value;
		const fromDateValue = this.datePipe.transform(this.reportForm.get("fromdate").value, "MM-dd-yyyy");
		const toDateValue = this.datePipe.transform(this.reportForm.get("todate").value, "MM-dd-yyyy");

		console.log("statusid=",statusid+", Fromdate=",fromDateValue +", Todate=",toDateValue);
		this.Report_Summary_OrderAgaingstDispatch(fromDateValue,toDateValue,statusid);
	}
	onPageSizeChange(page) {
		
		console.log("currentPage>>:",this.currentPage);
		// this.currentPage = page;
		this.pageSize = page;
		this.pageIndex = 1;	
		
		const statusid = this.reportForm.get("siteid").value;
		const fromDateValue = this.datePipe.transform(this.reportForm.get("fromdate").value, "MM-dd-yyyy");
		const toDateValue = this.datePipe.transform(this.reportForm.get("todate").value, "MM-dd-yyyy");

		console.log("statusid=",statusid+", Fromdate=",fromDateValue +", Todate=",toDateValue);
		this.Report_Summary_OrderAgaingstDispatch(fromDateValue,toDateValue,statusid);
	}

	// check sorting
	sortBy(property: string) {
        this.sortOrder = property === this.sortProperty ? (this.sortOrder * -1) : 1;
        this.sortProperty = property;
       
		if(this.sortOrder===1)
		{
			this.orderBy="ASC";
			console.log("sortOrderASC>>:",this.orderBy);

		}
		if(this.sortOrder===-1)
			{
				this.orderBy="DESC";
				console.log("sortOrderDESC>>:",this.orderBy);
	
			}
		console.log("sortOrder>>:", this.sortOrder);
		console.log("sortProperty>>:", this.sortProperty);

		const statusid = this.reportForm.get("siteid").value;
		const fromDateValue = this.datePipe.transform(this.reportForm.get("fromdate").value, "MM-dd-yyyy");
		const toDateValue = this.datePipe.transform(this.reportForm.get("todate").value, "MM-dd-yyyy");

		console.log("statusid=",statusid+", Fromdate=",fromDateValue +", Todate=",toDateValue);
		this.Report_Summary_OrderAgaingstDispatch(fromDateValue,toDateValue,statusid);
		
    }
	sortIcon(property: string) {
        if (property === this.sortProperty) {
           // return this.sortOrder === 1 ? '▲' : '▼';
			return this.sortOrder === 1 ? '▲' : '▼';
        }
        return '';
    }
}
