import {ChangeDetectorRef,Component,OnInit,EventEmitter,Output,ElementRef,ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterState } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
// import { AuthService } from 'src/app/modules/auth';
import { CoffeeService } from "../../coffee.service";
import { environment } from '../../../../environments/environment';

import { ViewProductModalComponent } from '../../Modals/view-product-modal/view-product-modal.component'; // Import your modal component
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CartService } from '../../cart.service';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material';
import { quantity } from 'chartist';

@Component({
  selector: 'kt-add-order',
  templateUrl: './add-order.component.html',
  styleUrls: ['./add-order.component.scss']
})
export class AddOrderComponent implements OnInit {

  items$: Observable<any[]>;
  productSearchForm: FormGroup;
  cart: any[] = []; // Initialize an empty cart array
  environment = environment;
  @Output() cartUpdated: EventEmitter<any[]> = new EventEmitter<any[]>();
  private cartSubject = new BehaviorSubject<any[]>([]);
  isLoading = false;
  currentPage = 1;
  itemsPerPage = 10;
  totalItems = 0;
  totalRecords: number = 0;
  totalLength: number = 119;
	

  searchKeyword: string;
  startItem = 1;
  endItem = 0;
  totalPages = 0;
  tableData = [];
  private modalRef: NgbModalRef | null = null;
  checkedItems: { id: any; text: any }[] = [];
  owner_company_id=0;
  owner_company_name: string = '';
  reportForm: FormGroup;
  // private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
  searchTerm: string = '';
  RoleID=0;
  TypeValue =12;
  SearchValue =0;

  imgSrc:string;
  imgCaption:string;
  

  constructor(private dialog: MatDialog, private fb: FormBuilder,private route: ActivatedRoute,private router: Router,private _service: CoffeeService,
    private cdRef: ChangeDetectorRef,private modalService: NgbModal,private cartService: CartService) {}


   
    ngOnInit(): void {
      this.checkedItems = [{ id: 'A', text: 'All' }];
      this.loadProducts(this.checkedItems);
  
      this.reportForm = this.fb.group({
       owner_company_id: ['']
        
      });
    
  
      setTimeout(() => {
        this.loadSelectOptions();
      }, 0);

      
       
    }

    setTimeLoadData(){
      setTimeout(() => {
        this.loadProducts(this.checkedItems);
      }, 2000);
    }


    ngSelectOptions = {
      placeholder: 'Select a Company',
      allowClear: true,
      minimumInputLength: 0,
      width: '50%',
    };
    selectOptions: any[] = [];
    // owner_company_id = 0;
  
    // modalConfig: ModalConfig = {
    //   modalTitle: 'Add Product to Cart',
    //   dismissButtonLabel: 'Submit',
    //   closeButtonLabel: 'Cancel',
    // };

  //   @ViewChild('content') private modalComponent: ViewProudctModalComponent;
  // async openModal() {
  //   return await this.modalComponent.open();
  // }

  addToCartActual(product: any, quantity: number) {
    
    this.cartService.addToCart(product, quantity);
    this.cartSubject.next(this.cart);
    
  }


  async addToCart(item: any) {
    // console.log("OwnID>>::",this.owner_company_id);
    // console.log("item>>::",item);

    // console.log("OwnIDNO>>::",this._service.own_comp_id);
    // console.log("itemName>>::",this._service.own_comp_name);

    // debugger

    if(this._service.own_comp_id!="0")

  //  if(this.owner_company_id!=0)
    {
    // this.modalComponent.product = item;
    // this.modalComponent.quantity = this.getItemCount(item.ProductId) || 1;
    // console.log('Selected Product:', item);
    // await this.modalComponent.open();  
    this.cartSubject.next(this.cart);
   }
   else{
    Swal.fire('Error', 'Please Select Company', 'error');
   }
   if(this._service.own_comp_id!="0"){
  //  if(this.owner_company_id!=0){
    const dialogRef = this.dialog.open(ViewProductModalComponent, {
      data: { product: item,quantity: this.getItemCount(item.ProductId) || 1},
    });
    this.cartSubject.next(this.cart);
    // You can handle dialog events here if needed
    dialogRef.afterClosed().subscribe((result) => {
      console.log("Dialog closed with result:", result);
      this.loadProducts(this.checkedItems);
    });
    
  
   }
   else{
    Swal.fire('Error', 'Please Select Company', 'error');
   }
      
  }

  isInCart(item: any): boolean {
    return this.cartService.isInCart(item);
  }

  onValueChange(value:any) {
    // handle the selected value..
    
   // console.log("Selected>::",value);
    if(value!='')
    {

      if(this._service.own_comp_id!=value)
      {
        this.cartService.clearCart();
        this._service.own_comp_id="0";
        this._service.own_comp_name="";
  
        this.owner_company_id=value;
        this._service.own_comp_id=value;
  
        let option =this.selectOptions.filter(s=>s.id==value);
        this.owner_company_name=option[0].text;
        this._service.own_comp_name=option[0].text;
        
        
      }
      this.loadProducts(this.checkedItems);

     

    }
    
   
    
  }

 
  onCheckedItemsChanged(checkedItems: any[]) {
    // Handle the checked items here in AddOrderComponent
   // console.log('Checked items:', checkedItems);
    // You can perform any further actions based on the checked items.
  }
  updateCartItemQuantity(item: any, newQuantity: string) {
    // Ensure the newQuantity is a valid number
    const parsedQuantity = parseInt(newQuantity, 10);
    if (!isNaN(parsedQuantity) && parsedQuantity >= 0) {
      if (parsedQuantity === 0) {
        // If the quantity is 0, remove the item from the cart
        this.cartService.removeFromCartItem(item);
      } else {
        // Otherwise, update the quantity using the cart service
        this.cartService.updateCartItemQuantity(item.ProductId, parsedQuantity);
        this.loadProducts(this.checkedItems);
      }
    }
  }

  updateCartItem(item: any) {
    // You can add validation logic here to ensure the new quantity is valid.
    // For example, check if the new quantity is greater than 0.

    // Update the cart item's quantity
    this.cartService.updateCartItemQuantity(item, item.quantity);

    // Refresh the cart
    this.cartSubject.next(this.cart);
  }
  updateCart() {
    this.cart = this.cartService.getCart();
    this.cdRef.detectChanges();
  }
  getCartItemCount(): number {
    return this.cartService.getCart().length;
  }
  generateImageUrl(imageName: string): string {
    return `${this.environment.BaseUrl}/productImages/${imageName}`;
  }

  removeFromCart(item: any) {
    this.cartService.removeFromCart(item);
  }

  getCartItems(): any[] {
    return this.cartService.getCart();
  }

  getCart(): Observable<any[]> {
    return this.cartSubject.asObservable();
  }

  getItemCount(productId: number): number {
    return this.cartService.getItemCount(productId);
  }

  //
  increaseCartItem(item: any) {
    const newQuantity = 1;
    this.addToCartActual(item, newQuantity);
  }

  decreaseCartItem(item: any) {
    this.removeFromCart(item);
  }

  onSearch() {
    this.currentPage = 1; // Reset to the first page when searching
    this.loadProducts(this.checkedItems);
  }

  onPageChange(page: number) {
    this.currentPage = page;
    this.loadProducts(this.checkedItems);
  }

  getTotalPages(): number {
    return Math.ceil(this.totalItems / this.itemsPerPage);
  }

  getPages(): number[] {
    const totalPages = this.getTotalPages();
    return Array.from({ length: totalPages }, (_, i) => i + 1);
  }

  getTotalCartItems(): number {
    return this.cartService
      .getCart()
      .reduce((total, item) => total + item.quantity, 0);
  }

  calculateDisplayRange() {
    this.startItem = Math.min(
      (this.currentPage - 1) * this.itemsPerPage + 1,
      this.totalItems
    );
    this.endItem = Math.min(
      this.currentPage * this.itemsPerPage,
      this.totalItems
    );
  }

  async loadProducts(checkedItems: { id: any; text: any }[]) {
    this.isLoading = true;
    const own_comp_id = this._service.own_comp_id;
    this._service
      .productsGETOrder(
        this.currentPage,
        this.itemsPerPage,
        '',
        this.searchKeyword,
        checkedItems,own_comp_id
      )
      .subscribe((response: any) => {
        this.items$ = of(response);
        if ( 
          response &&
          response.length > 0 &&
          response[0].hasOwnProperty('Rcount')
        ) { console.log("response>>::",response);
          this.totalPages = Math.ceil(response[0].Rcount / this.itemsPerPage);
        } else {
          // Handle the case where 'Rcount' is not available in the response
          console.error(
            "Response does not contain 'Rcount' property or is in an unexpected format."
          );
          // You can set some default values or show an error message here if needed.
        }
        this.totalRecords = response[0].TotalRecords;
        this.totalLength =response[0].TotalRecords;
        this.isLoading = false;
        this.calculateDisplayRange(); // Calculate display range after loading products
        this.cdRef.markForCheck();
        this.cdRef.detectChanges();
      });
  }

  // checkboxChange(item: any) {
  //   // Handle the checkbox change here.
  //   console.log('Checkbox changed:', item);

  // }
  checkboxChange(checkedItems: { id: any; text: any }[]) {
    // Update the checked items array in the AddOrderComponent
    this.checkedItems = checkedItems;

    if (checkedItems.length == 0)
      this.checkedItems = [{ id: 'A', text: 'All' }];

    this.currentPage = 1;
    this.loadProducts(this.checkedItems);
    console.log('Checked Items:', this.checkedItems);
  }

loadSelectOptions() {


if(this._service.own_comp_id=="0" || this._service.own_comp_id==null)
{
  var userdata = JSON.parse(localStorage.getItem("user"));

  this.RoleID = userdata.roleId;

  if(this.RoleID==6)
  {
    this.TypeValue=22;
    this.SearchValue=userdata.id;
  }

    this._service
      .dropdownAll(this.searchTerm, '1', this.TypeValue.toString(), this.SearchValue.toString())
      .subscribe(
        (data) => {
          this.selectOptions = data;
          console.log('selectOptions:', this.selectOptions);
          console.log(data);
          console.log("UserID>>:",userdata.id);
          this.cdRef.markForCheck();
          this.cdRef.detectChanges();
        },
        (error) => {
          console.error('Error loading select options:', error);
        }
      );
}
else{
  let CompanyDataItems: { id: any; text: any }[] = [];

 
  CompanyDataItems.splice(0,0,{id: this._service.own_comp_id,text: this._service.own_comp_name});
  this.selectOptions=CompanyDataItems
  this.owner_company_id= parseInt(this._service.own_comp_id);
  this.owner_company_name=this._service.own_comp_name;
  
}

    
  }

 
  onPageSizeChange(page) {
		
		console.log("currentPage>>:",this.currentPage);
		// this.currentPage = page;
		this.itemsPerPage = page;
		this.currentPage = 1;	

   // this.currentPage = page;
    this.loadProducts(this.checkedItems);
		
		
	} 

  ClearAllProduct(){
    this.cartService.clearCart();
    this._service.own_comp_id="0";
    this._service.own_comp_name="";
    this.owner_company_id=0;
    
    this.loadSelectOptions();
    this.loadProducts(this.checkedItems);
    console.log("ClearData");
}
  
onClick(event){
  
  const imgElem = event.target;
  var target = event.target || event.srcElement || event.currentTarget;
  var srcAttr = target.attributes.src;
  //var srcaaatt = target.attributes.
   this.imgSrc = srcAttr.nodeValue;
   this.imgCaption = target.alt ;
}
onClick1(event,ImgUrl:string,ProductName:string){
  
  ImgUrl = this.generateImageUrl1(ImgUrl);
  this.imgSrc = ImgUrl;
  this.imgCaption = ProductName ;
}

generateImageUrl1(imageName: string): string { 
 
  if(imageName.length>0)
  {   
    return `${this.environment.BaseUrl}/productImages/${imageName}`;
  }
  else{
     const img = new Image();
    return img.src = "assets/img/no-images.png";
  }
 
}


  
      


}
