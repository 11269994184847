import { ChangeDetectorRef, AfterViewInit, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MatSnackBar } from "@angular/material";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { CoffeeService } from "../../coffee.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ViewOrderComponent } from "../../Modals/view-order/view-order.component";
import { environment } from "../../../../../src/environments/environment";
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from "rxjs/operators";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfirmationModalComponent } from "../../Modals/confirmation-modal/confirmation-modal.component";
import { Router } from "@angular/router";
import { SharedDataService } from "../../shared-data.service";
import{NgSelect2Component,NgSelect2Module,Select2OptionData} from "ng-select2";

@Component({
	selector: "kt-dispatch-plan",
	templateUrl: "./dispatch-plan.component.html",
	styleUrls: ["./dispatch-plan.component.scss"],
})
export class DispatchPlanComponent implements OnInit {
	@ViewChild("wizard", { static: true }) el: ElementRef;

	submitted = false;
	loading = false;
	totalLength: number = 119;
	pageIndex: number = 1;
	pageSize: number = 10;
	ordersList$: Observable<any[]>;
	ordersPlanList$: Observable<any[]>;
	loadingTable = true;
	statusData: any[] = [];
	statusOptions: any = {};
	statusOptionsConfig: any = {};
	planQtyValues: { [orders_details_id: string]: number } = {};
	cart: { orders_details_id: number; planQty: number; orderNo: string; Product: string; element: any[] }[] = [];

	private ordersListSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

	private ordersPlanSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

	deliveryTypesData: any[] = [];
	deliveryTypesOptions: any = {};
	deliveryTypesOptionsConfig: any = {};

	packagingTypesData: any[] = [];
	packagingTypesOptions: any = {};
	packagingTypesOptionsConfig: any = {};
	public form: FormGroup;
	public companyData: any;
	selectedFiles: { docket?: File; invoice?: File } = {};
	minDate: Date;
	maxDate: Date;

	searchOwner: string;
	OwnerData$: Observable<any[]>;

	searchDeliveryType: string;
	DeliveryTypeData$: Observable<any[]>;

	searchPackingType: string;
	PackingTypeData$: Observable<any[]>;

	ordersEditPlan$: Observable<any[]>;
	private OrdersEditPlanSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

	private subscription: Subscription;

	constructor(private fb: FormBuilder, private sharedDataService: SharedDataService, private _router: Router, private dialog: MatDialog, private dialog_view: MatDialog, public snackBar: MatSnackBar, private ref: ChangeDetectorRef, private coffeeService: CoffeeService, private spinner: NgxSpinnerService) {
		this.ordersList$ = this.ordersListSubject.asObservable();
		this.ordersPlanList$ = this.ordersPlanSubject.asObservable();

		this.ordersEditPlan$ = this.OrdersEditPlanSubject.asObservable();

		const currentYear = new Date().getFullYear();
		this.minDate = new Date(currentYear - 20, 0, 1);
		this.maxDate = new Date(currentYear + 1, 11, 31);
		this.form = this.fb.group({
			DispatchPlanId: ["0", Validators.required],
			customerId: ["", Validators.required],
			planDate: ["", Validators.required],
			delivery_type: [null, Validators.required],
			packaging_type: [null, Validators.required],
			docket_no: [null, Validators.required],
			transporterName: [null, Validators.required],
		});

		this.form.get("customerId").valueChanges.subscribe((value) => {
			// Do something with the changed value
			console.log("customerId value changed:", value);

			if (value !== "") {
				this.coffeeService.OwnersCompayGetById(value).subscribe(
					(res: any) => {
						//console.log("LOG:", res);
						this.companyData = res[0];

						this.ref.markForCheck();
						this.ref.detectChanges();
					},
					(error: any) => {
						this.snackBar.open(error.error.message, "OK", {
							duration: 3000,
						});
					}
				);
			}
		});
	}

	selectedOrderIds: number[] = [];
	previousPlanQty: number;

	onFileChange(event: any, fileType: string): void {
		const file = event.target.files[0];
		this.selectedFiles[fileType] = file;
	}

	setupSearch() {
		//Owner
		this.OwnerData$ = new Observable<string>((observer) => {
			observer.next(this.searchOwner);
		}).pipe(
			startWith(""),
			debounceTime(300),
			distinctUntilChanged(),
			switchMap((query: string) => {
				console.log("Search Query:", query);
				query = query || "";

				return this.coffeeService.dropdownAll(query, "1", "12", "0");
			})
		);

		this.DeliveryTypeData$ = new Observable<string>((observer) => {
			observer.next(this.searchDeliveryType);
		}).pipe(
			startWith(""),
			debounceTime(300),
			distinctUntilChanged(),
			switchMap((query: string) => {
				console.log("Search Query:", query);
				query = query || "";

				return this.coffeeService.dropdownAll(query, "1", "10", "0");
			})
		);

		this.PackingTypeData$ = new Observable<string>((observer) => {
			observer.next(this.searchPackingType);
		}).pipe(
			startWith(""),
			debounceTime(300),
			distinctUntilChanged(),
			switchMap((query: string) => {
				console.log("Search Query:", query);
				query = query || "";

				return this.coffeeService.dropdownAll(query, "1", "11", "0");
			})
		);
	}
	submitForm() {
		this.submitted = true;

		if (this.form.invalid) {
			// Mark all form controls as touched to trigger the display of error messages
			Object.values(this.form.controls).forEach((control) => control.markAsTouched());
			this.submitted = false;
			return;
		}

		const formData = new FormData();
		formData.append("docket", this.selectedFiles.docket);
		formData.append("invoice", this.selectedFiles.invoice);

		formData.append(
			"dispatchPlanData",
			JSON.stringify({
				UserId: 0,
				DispatchPlanId: 0,
				email: this.companyData.EmailId,
				owner_company_id: parseInt(this.form.value.customerId, 10),
				DispatchPlanDate: this.form.value.planDate,
				DeliveryTypeId: parseInt(this.form.value.delivery_type, 10),
				PackagingTypeId: parseInt(this.form.value.packaging_type, 10),
				docket_no: this.form.value.docket_no,
				transporterName: this.form.value.transporterName,
				DispatchPlanDetails: this.cart.map((item) => ({
					OrdersDetailsId: item.orders_details_id,
					PlanQty: item.planQty,
					OrderNo: item.orderNo,
					Product: item.Product,
				})),
			})
		);
		// Now, use HttpClient to send the data
		this.coffeeService.DispatchPlanInsertUpdate(formData).subscribe(
			(res: any) => {
				if (res.error === false) {
					this.snackBar.open("Error occurred while saving", "OK", {
						duration: 3000,
					});
				} else {
					this.snackBar.open("Saved Successfully", "Undo", {
						duration: 3000,
					});
					this._router.navigate(["/coffee/dispatch-plan-data"]);
				}
			},
			(error: any) => {
				this.snackBar.open(error.error, "OK", {
					duration: 3000,
				});
			}
		);
	}

	submitForm2() {
		this.submitted = true;

		if (this.form.invalid) {
			// Mark all form controls as touched to trigger the display of error messages
			Object.values(this.form.controls).forEach((control) => control.markAsTouched());
			this.submitted = false;
			return;
		}

		const formData = new FormData();
		formData.append("docketFile", this.form.get("docketFile").value);
		formData.append("invoiceFile", this.form.get("invoiceFile").value);

		const dispatchPlanData = {
			UserId: 0,
			owner_company_id: parseInt(this.form.value.customerId, 10),
			DispatchPlanDate: this.form.value.planDate,
			DeliveryTypeId: parseInt(this.form.value.delivery_type, 10),
			PackagingTypeId: parseInt(this.form.value.packaging_type, 10),
			DispatchPlanDetails: this.cart.map((item) => ({
				OrdersDetailsId: item.orders_details_id,
				PlanQty: item.planQty,
				OrderNo: item.orderNo,
				Product: item.Product,
			})),
		};

		// this.coffeeService.DispatchPlanInsertUpdate(dispatchPlanData).subscribe(
		// 	(res: any) => {
		// 		if (res.error === false) {
		// 			this.snackBar.open("Error occurred while saving", "OK", {
		// 				duration: 3000,
		// 			});
		// 		} else {
		// 			this.snackBar.open("Saved Successfully", "Undo", {
		// 				duration: 3000,
		// 			});
		// 			this._router.navigate(["/coffee/dispatch-plan-data"]);
		// 		}
		// 	},
		// 	(error: any) => {
		// 		this.snackBar.open(error.error.message, "OK", {
		// 			duration: 3000,
		// 		});
		// 	}
		// );
	}

	addOrder(orderId: number) {
		if (!this.selectedOrderIds.includes(orderId)) {
			this.selectedOrderIds.push(orderId);
			this.snackBar.open("Order No  " + orderId + " is added", "Dismiss", {
				duration: 3000,
			});
		} else {
			this.snackBar.open(orderId + " Order is already added", "Dismiss", {
				duration: 5000,
				panelClass: ["error-snackbar"],
			});
		}
	}

	isSelected(orderId: number): boolean {
		return this.selectedOrderIds.includes(orderId);
	}
	onPlanQtyChange(element: any) {
		if (element.plan_qty > element.order_qty) {
			// If the new plan_qty is invalid, revert to the previous valid value.
            
			const cartItemIndex = this.cart.findIndex((item) => item.orders_details_id === element.orders_details_id);
			if (cartItemIndex !== -1) {
				this.cart.splice(cartItemIndex, 1);
			}

			element.plan_qty = this.planQtyValues[element.orders_details_id];
		} else if (element.plan_qty === 0) {
			// Remove the item from the cart if plan_qty is 0.
			const cartItemIndex = this.cart.findIndex((item) => item.orders_details_id === element.orders_details_id);
			if (cartItemIndex !== -1) {
				this.cart.splice(cartItemIndex, 1);
			}
		} else {
			// Update the planQtyValues with the current valid value.
			this.planQtyValues[element.orders_details_id] = element.plan_qty;

			
			

			// Update the cart array with the plan_qty value
			const cartItem = this.cart.find((item) => item.orders_details_id === element.orders_details_id);
			if (cartItem) {
				let index =	this.cart.findIndex((item) => item.orders_details_id === element.orders_details_id);
				
				if (element.pre_remain_qty >= element.plan_qty)
				{
					
					if(element.plan_qty<=0)
					{
						cartItem.planQty =0;
						element.plan_qty=0;
						this.cart.splice(index,1);
					}
					else{
						cartItem.planQty = element.plan_qty;
						this.cart.splice(index,1);
						this.cart.push({ orders_details_id: element.orders_details_id, planQty: element.plan_qty, orderNo: element.order_no, Product: element.ProductName, element: element });

					}
					

				}
				else{
					cartItem.planQty =0;
					element.plan_qty=0;					
					this.cart.splice(index,1);
				}
				
			} else {
				if(element.plan_qty>0)
			    {
				this.cart.push({ orders_details_id: element.orders_details_id, planQty: element.plan_qty, orderNo: element.order_no, Product: element.ProductName, element: element });
			
				}

				}
			
		}

		
		// Update remain_qty
		element.remain_qty = this.calculateRemainQty(element);
		//console.log(this.cart);
	}

	calculateRemainQty(element: any): number {
		// Calculate remain_qty based on plan_qty
		if (element.pre_remain_qty > element.plan_qty){
			if(element.plan_qty<=0)
				{					
					return element.pre_remain_qty;
				}
				
			return element.pre_remain_qty - element.plan_qty;
		}
		else if(element.pre_remain_qty == element.plan_qty)
		{
			return 0;
		}
		else if(element.pre_remain_qty < element.plan_qty)
			{
				
				return element.pre_remain_qty;
			}
	    else
		{
			
			return 0;
		}
		
	}
	isInvalidPlanQty(element: any): boolean {
		if(element.plan_qty<0)
				{					
					return true;
				}
				
		return element.plan_qty > element.pre_remain_qty;
	}

	removeCartItemItem(orders_details_id: number) {		
		const cartItemIndex = this.cart.findIndex((item) => item.orders_details_id === orders_details_id);		
				if (cartItemIndex !== -1) {					
				this.cart.splice(cartItemIndex, 1);
				}
			}

	removeOrder(order_id: number) {
		const index = this.selectedOrderIds.indexOf(order_id);
		if (index !== -1) {
			this.selectedOrderIds.splice(index, 1);
		}
	}

	ngAfterViewInit(): void {
		// Initialize form wizard
		const wizard = new KTWizard(this.el.nativeElement, {
			startStep: 1,
		});

		// Validation before going to next page
		wizard.on("beforeNext", (wizardObj) => {
			// https://angular.io/guide/forms
			// https://angular.io/guide/form-validation
			// validate the form and use below function to stop the wizard's step
			// wizardObj.stop();
		});

		// Change event
		wizard.on("change", () => {
			setTimeout(() => {
				KTUtil.scrollTop();
			}, 500);

			const currentStepIndex = wizard.getStep();

			if (currentStepIndex === 2) {
				this.OrdersGETDispatchPlan(this.form.value.customerId);
			}

			if (currentStepIndex === 3) {
				this.ordersPlanSubject.next(null);
				this.loadingTable = true;

				this.OrdersDetailsMultipleOrderIdGET(this.selectedOrderIds);
				this.ordersPlanList$ = this.ordersPlanList$.pipe(
					map((orders) => {
						if (orders) {
							return orders.map((order) => {
								const cartItem = this.cart.find((item) => item.orders_details_id === order.orders_details_id);
								if (cartItem) {
									// Update the plan_qty property if a corresponding cart item is found
									order.plan_qty = cartItem.planQty;
								}
								return order;
							});
						} else {
							// Handle the case when orders is null or undefined
							return [];
						}
					})
				);
			} else if (currentStepIndex == 3) {
				this.dropDownDeliveryTypes();
				this.dropDownPackingTypes();
			}
		});

		// Get the current step index
	}
	viewOrder(orderId: number) {
		const dialogRef = this.dialog.open(ViewOrderComponent, {
			data: { orderId: orderId },
		});

		// Handle dialog events here if needed
		dialogRef.afterClosed().subscribe((result) => {
			console.log("Dialog closed with result:", result);
		});
	}

	onSubmit() {
		const dialogRef = this.dialog.open(ConfirmationModalComponent, {
			data: {
				title: "Confirmation",
				description: "Are you sure you want to submit data",
				waitDescription: "Please wait we are submitting the data",
			},
			width: "440px",
		});
		dialogRef.afterClosed().subscribe((res) => {
			if (!res) {
				return;
			}
			this.submitForm();
		});
	}
	ngOnInit() {
		var userdata = JSON.parse(localStorage.getItem("user"));
		this.EditMode();
		this.setupSearch();
		this.dropDownDeliveryTypes();
		this.dropDownPackingTypes();
	}
	statusChanged(event) {
		console.log("Status Changed:", event);
	}

	EditMode() {
		this.subscription = this.sharedDataService.dispatchPlanData$.subscribe((data) => {
			var obj = JSON.parse(data);

			if (obj != null) {
				this.form.controls.DispatchPlanId.setValue(obj.DispatchPlanId.toString());
				this.form.controls.customerId.setValue(obj.owner_company_id.toString());

				const dispatchPlanDate = new Date(obj.DispatchPlanDate);

				this.form.controls.planDate.setValue(dispatchPlanDate);
				this.form.controls.delivery_type.setValue(obj.DeliveryTypeId.toString());
				this.form.controls.packaging_type.setValue(obj.PackagingTypeId.toString());
				this.form.controls.docket_no.setValue(obj.docket_no.toString());
				this.form.controls.transporterName.setValue(obj.transporterName.toString());
				this.coffeeService.DispatchPlanGetForEdit(obj.DispatchPlanId).subscribe((response: any[]) => {
					this.selectedOrderIds = response.map((item) => item.order_id);
					console.log(this.selectedOrderIds);
				});

				this.OrdersDetailsGET(obj.DispatchPlanId);
			}
		});

		this.sharedDataService.clearDispatchPlanData();
	}

	OrdersGETDispatchPlan(ownerid: string) {
		const startIndex = this.pageIndex; // Set your desired values for the parameters
		const pageSize = this.pageSize;
		const searchBy = "";
		const searchCriteria = "";
		this.spinner.show();
		const owner_company_id = ownerid;

		this.coffeeService.OrdersGETDispatchPlan(startIndex, pageSize, searchBy, searchCriteria, owner_company_id).subscribe((response: any[]) => {
			this.ordersListSubject.next(response);
			this.spinner.hide();
		});
	}

	OrdersDetailsMultipleOrderIdGET(selectedOrderIds: number[]) {
		this.loadingTable = true;
		this.coffeeService.OrdersDetailsMultipleOrderIdGET(selectedOrderIds).subscribe((response: any[]) => {
			this.ordersPlanSubject.next(response);
			this.spinner.hide();
			this.loadingTable = false;
			this.ref.detectChanges();
		});
	}

	dropDownDeliveryTypes() {
		var userdata = JSON.parse(localStorage.getItem("user"));

		this.deliveryTypesOptionsConfig = {
			ajax: {
				url: environment.BaseUrl + "api/Owner/allDropdown",
				dataType: "json",
				delay: 250,
				headers: {
					Authorization: "Bearer " + userdata.token,
				},
				data: (params: any) => {
					return {
						searchTerm: params.term,
						page: params.page,
						type: 10,
						parentId: 0,
					};
				},
				processResults: (data: any) => {
					const results = data.map((item: any) => {
						return {
							id: item.id,
							text: item.text,
						};
					});

					return {
						results: results,
					};
				},
				cache: true,
			},
			placeholder: "Select a Delivery Type",
			minimumInputLength: 0,
		};
	}

	dropDownPackingTypes() {
		var userdata = JSON.parse(localStorage.getItem("user"));

		this.packagingTypesOptionsConfig = {
			ajax: {
				url: environment.BaseUrl + "api/Owner/allDropdown",
				dataType: "json",
				delay: 250,
				headers: {
					Authorization: "Bearer " + userdata.token,
				},
				data: (params: any) => {
					return {
						searchTerm: params.term,
						page: params.page,
						type: 11,
						parentId: 0,
					};
				},
				processResults: (data: any) => {
					const results = data.map((item: any) => {
						return {
							id: item.id,
							text: item.text,
						};
					});

					return {
						results: results,
					};
				},
				cache: true,
			},
			placeholder: "Select a Delivery Type",
			minimumInputLength: 0,
		};
	}

	getOwnerConfig(): any {
		var userdata = JSON.parse(localStorage.getItem("user"));
		return {
			ajax: {
				url: environment.BaseUrl + "api/Owner/allDropdown",
				dataType: "json",
				delay: 250,
				headers: {
					Authorization: "Bearer " + userdata.token,
				},
				data: (params: any) => {
					return {
						searchTerm: params.term,
						page: params.page,
						type: 12,
						parentId: "0",
					};
				},
				processResults: (data: any) => {
					this.loading = false;
					const results = data.map((item: any) => {
						return {
							id: item.id,
							text: item.text,
						};
					});

					return {
						results: results,
					};
				},
				cache: true,
			},
			placeholder: "Select a Owners",
			minimumInputLength: 0,
		};
	}

	getDeliveryTypeConfig(): any {
		var userdata = JSON.parse(localStorage.getItem("user"));
		return {
			ajax: {
				url: environment.BaseUrl + "api/Owner/allDropdown",
				dataType: "json",
				delay: 250,
				headers: {
					Authorization: "Bearer " + userdata.token,
				},
				data: (params: any) => {
					return {
						searchTerm: params.term,
						page: params.page,
						type: 10,
						parentId: "0",
					};
				},
				processResults: (data: any) => {
					this.loading = false;
					const results = data.map((item: any) => {
						return {
							id: item.id,
							text: item.text,
						};
					});

					return {
						results: results,
					};
				},
				cache: true,
			},
			placeholder: "Select a Owners",
			minimumInputLength: 0,
		};
	}

	getPackingTypeConfig(): any {
		var userdata = JSON.parse(localStorage.getItem("user"));
		return {
			ajax: {
				url: environment.BaseUrl + "api/Owner/allDropdown",
				dataType: "json",
				delay: 250,
				headers: {
					Authorization: "Bearer " + userdata.token,
				},
				data: (params: any) => {
					return {
						searchTerm: params.term,
						page: params.page,
						type: 11,
						parentId: "0",
					};
				},
				processResults: (data: any) => {
					this.loading = false;
					const results = data.map((item: any) => {
						return {
							id: item.id,
							text: item.text,
						};
					});

					return {
						results: results,
					};
				},
				cache: true,
			},
			placeholder: "Select a Owners",
			minimumInputLength: 0,
		};
	}

	// Exisiting Dispatch Plan Get

	OrdersDetailsGET(DispatchPlanId) {
		this.spinner.show();
		this.coffeeService.DispatchPlanDetailsGET(DispatchPlanId).subscribe((response: any[]) => {
			response.forEach((item) => {
				const cartItem = {
					orders_details_id: item.orders_details_id,
					planQty: item.plan_qty,
					orderNo: item.order_no,
					Product: item.ProductName,
					element: item,
				};

				this.cart.push(cartItem);
			});

			// Notify subscribers that the cart has been updated
			this.OrdersEditPlanSubject.next(this.cart);
			this.spinner.hide();
		});
	}
}
