import { ChangeDetectorRef, AfterViewInit, Component, ElementRef, OnInit, ViewChild,NgModule } from "@angular/core";
import { MatDialog, MatSnackBar } from "@angular/material";
import { BehaviorSubject, Observable } from "rxjs";
import { CoffeeService } from "../../coffee.service";
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from "../../../../../src/environments/environment";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DatePipe } from "@angular/common";
import{NgSelect2Component,NgSelect2Module,Select2OptionData} from "ng-select2";
import { result } from "lodash";

@Component({
  selector: 'kt-rpt-dispatch-serialnumber',
  templateUrl: './rpt-dispatch-serialnumber.component.html',
  styleUrls: ['./rpt-dispatch-serialnumber.component.scss']
})
export class RptDispatchSerialnumberComponent implements OnInit {

  totalLength: number = 119;
	pageIndex: number = 1;
	pageSize: number = 10;
	
	//Pagination
	totalPages: number = 50;
	currentPage: number = 1;
	totalRecords: number = 0;
	itemsPerPage = 10;
	totalItems = 0;
	searchQuery: string = "";
	loading = false;
	
	// New Check Sorting
	SortColumn: string = 'ScannedSerialId';
    OrderSort = 1;
    SortOrder:string="DESC";

    SerialNumberList$: Observable<any[]>;


    CompanyData$: Observable<any[]>;
    CompanyOptions: any = {};
    CompanyOptionsConfig: any = {};
  
    CategoryData$: Observable<any[]>;
    CategoryData: any[]=[];
    CategoryOptions: any = {};
    CategoryOptionsConfig: any = {};
    CategotyId:string="0";

    ProductData$: Observable<any[]>;
    ProductData: any[]=[];
    
    ProductOptions: any = {};
    ProductOptionsConfig: any = {};

    loadingSubmit = false;
    reportForm: FormGroup;
    private SerialNumberListSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  constructor(private fb: FormBuilder,private datePipe: DatePipe,public snackBar: MatSnackBar, private ref: ChangeDetectorRef, private coffeeService: CoffeeService, private spinner: NgxSpinnerService) { 
    
    this.SerialNumberList$ = this.SerialNumberListSubject.asObservable();

    var userdata = JSON.parse(localStorage.getItem("user"));

    this.CategoryOptionsConfig = {
     
			ajax: {
			  url: environment.BaseUrl + "api/Owner/allDropdown",
			  dataType: "json",
			  delay: 250,
			  headers: {
				Authorization: "Bearer " + userdata.token,
			  },
			  data: (params: any) => {
				return {
				  searchTerm: params.term,
				  page: params.page,
				  type: 5,
				  parentId: 0,
				};
			  },
			  processResults: (data: any) => {
				const results = data.map((item: any) => {
				  return {
					id: item.id,
					text: item.text,
				  };
				});
				results.splice(0,0,{id: 0,text: "Show All"});
				return {
				  results: results,
				};
			  },
			  cache: true,
			},
			placeholder: "Select a Category",
			minimumInputLength: 0,
		  };

      this.CompanyOptionsConfig = {
     
        ajax: {
          url: environment.BaseUrl + "api/Owner/allDropdown",
          dataType: "json",
          delay: 250,
          headers: {
            Authorization: "Bearer " + userdata.token,
          },
          data: (params: any) => {
            return {
              searchTerm: params.term,
              page: params.page,
              type: 12,
              parentId: 0,
            };
          },
          processResults: (data: any) => {
            const results = data.map((item: any) => {
              return {
                id: item.id,
                text: item.text,
              };
            });
            results.splice(0,0,{id: 0,text: "Show All"});
            return {
              results: results,
            };
          },
          cache: true,
        },
        placeholder: "Select a Company",
        minimumInputLength: 0,
      };

      this.ProductOptionsConfig = {
     
        ajax: {
          url: environment.BaseUrl + "api/Owner/allDropdown",
          dataType: "json",
          delay: 250,
          headers: {
          Authorization: "Bearer " + userdata.token,
          },
          data: (params: any) => {
          return {
            searchTerm: params.term,
            page: params.page,
            type: 23,
            parentId: this.CategotyId,
          };
          },
          processResults: (data: any) => {
          const results = data.map((item: any) => {
            return {
            id: item.id,
            text: item.text,
            };
          });
          results.splice(0,0,{id: 0,text: "Show All"});
          return {
            results: results,
          };
          },
          cache: true,
        },
        placeholder: "Select a Product",
        minimumInputLength: 0,
        };
  
	

  }

  ngOnInit() {

    this.reportForm = this.fb.group({
			CategoryId: [""],
      ProductId: [""],
      owner_company_id: [""],
      StartSerialNo: [""],
      EndSerialNo: [""],
			
		});


    this.DispatchProductSerialNoGET(null,null,null,null,null);
  }

  DispatchProductSerialNoGET(StartSerialNo: string, EndSerialNo: string,owner_company_id: string,CategoryId: string,ProductId:string) {
		const startIndex = this.pageIndex; // Set your desired values for the parameters
		const pageSize = this.pageSize;
		
		this.spinner.show();
		this.loadingSubmit = true;

		const SortColumn = this.SortColumn;
		const SortOrder=this.SortOrder;
	

		this.coffeeService.DispatchProductSerialNoGET(startIndex, pageSize, StartSerialNo,EndSerialNo,owner_company_id,CategoryId,ProductId,SortColumn,SortOrder).subscribe((response: any[]) => {
			this.SerialNumberListSubject.next(response);
			this.loadingSubmit = false;
			this.totalPages = response[0].TotalPages;
			this.totalRecords = response[0].TotalRecords;
      this.totalLength =response[0].TotalRecords;
			this.spinner.hide();
			this.loadingSubmit = false;

      console.log(response);
			
		});
	}

  onSubmit(){
		if (this.reportForm.invalid) {
			Object.values(this.reportForm.controls).forEach((control) => control.markAsTouched());
			return;
		}

    this.pageIndex=1;
    this.currentPage=1;
    this.totalPages=0;
		const owner_company_id = this.reportForm.get("owner_company_id").value;
    const CategoryId=this.reportForm.get("CategoryId").value;
    const ProductId = this.reportForm.get("ProductId").value;
    const StartSerialNo = this.reportForm.get("StartSerialNo").value;
    const EndSerialNo = this.reportForm.get("EndSerialNo").value;
	
		
		this.DispatchProductSerialNoGET(StartSerialNo,EndSerialNo,owner_company_id,CategoryId,ProductId);
	}

  resetform() {
		this.reportForm.reset();
    this.DispatchProductSerialNoGET(null,null,null,null,null);
	}


  onPageChange(page) {
		this.currentPage = page;		
		this.pageIndex = page;
		//this.pageSize = page.pageSize;		
    const owner_company_id = this.reportForm.get("owner_company_id").value;
    const CategoryId=this.reportForm.get("CategoryId").value;
    const ProductId = this.reportForm.get("ProductId").value;
    const StartSerialNo = this.reportForm.get("StartSerialNo").value;
    const EndSerialNo = this.reportForm.get("EndSerialNo").value;		
		this.DispatchProductSerialNoGET(StartSerialNo,EndSerialNo,owner_company_id,CategoryId,ProductId);
	}

  onPageSizeChange(page) {
		
		console.log("currentPage>>:",this.currentPage);
		// this.currentPage = page;
		this.pageSize = page;
		this.pageIndex = 1;	
		
		const owner_company_id = this.reportForm.get("owner_company_id").value;
    const CategoryId=this.reportForm.get("CategoryId").value;
    const ProductId = this.reportForm.get("ProductId").value;
    const StartSerialNo = this.reportForm.get("StartSerialNo").value;
    const EndSerialNo = this.reportForm.get("EndSerialNo").value;		
		this.DispatchProductSerialNoGET(StartSerialNo,EndSerialNo,owner_company_id,CategoryId,ProductId);
	}

  onCompanyChange(value:any){
    this.pageIndex=1;
    this.currentPage=1;
    this.totalPages=0;
    const owner_company_id = value;
    const CategoryId=this.reportForm.get("CategoryId").value;
    const ProductId = this.reportForm.get("ProductId").value;
    const StartSerialNo = this.reportForm.get("StartSerialNo").value;
    const EndSerialNo = this.reportForm.get("EndSerialNo").value;		
		this.DispatchProductSerialNoGET(StartSerialNo,EndSerialNo,owner_company_id,CategoryId,ProductId);
  }

  onCategoryChange(value:any)
	{
		this.pageIndex=1;
		this.currentPage=1;
		this.totalPages=0;



	  const owner_company_id = this.reportForm.get("owner_company_id").value;
    const CategoryId=value;
    const ProductId = this.reportForm.get("ProductId").value;
    const StartSerialNo = this.reportForm.get("StartSerialNo").value;
    const EndSerialNo = this.reportForm.get("EndSerialNo").value;		
		this.DispatchProductSerialNoGET(StartSerialNo,EndSerialNo,owner_company_id,CategoryId,ProductId);
    this.CategotyId=value.toString();
    this.ProductOptionsConfig();
	}

  onProductChange(value:any)
	{
		this.pageIndex=1;
		this.currentPage=1;
		this.totalPages=0;
	  const owner_company_id = this.reportForm.get("owner_company_id").value;
    const CategoryId=this.reportForm.get("CategoryId").value;
    const ProductId = value;
    const StartSerialNo = this.reportForm.get("StartSerialNo").value;
    const EndSerialNo = this.reportForm.get("EndSerialNo").value;		
		this.DispatchProductSerialNoGET(StartSerialNo,EndSerialNo,owner_company_id,CategoryId,ProductId);
	}
// check sorting
sortBy(property: string) {
  this.OrderSort = property === this.SortColumn ? (this.OrderSort * -1) : 1;
  this.SortColumn = property;
 
if(this.OrderSort===1)
{
this.SortOrder="ASC";
console.log("sortOrderASC>>:",this.SortOrder);

}
if(this.OrderSort===-1)
{
  this.SortOrder="DESC";
  console.log("sortOrderDESC>>:",this.SortOrder);

}


const owner_company_id = this.reportForm.get("owner_company_id").value;
const CategoryId=this.reportForm.get("CategoryId").value;
const ProductId = this.reportForm.get("ProductId").value;
const StartSerialNo = this.reportForm.get("StartSerialNo").value;
const EndSerialNo = this.reportForm.get("EndSerialNo").value;		
this.DispatchProductSerialNoGET(StartSerialNo,EndSerialNo,owner_company_id,CategoryId,ProductId);
}
sortIcon(property: string) {
  if (property === this.SortColumn) {
     // return this.sortOrder === 1 ? '▲' : '▼';
return this.OrderSort === 1 ? '▲' : '▼';
  }
  return '';
}


}
