import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CartService {
  private cartSubject = new BehaviorSubject<any[]>([]);
  cart$: Observable<any[]> = this.cartSubject.asObservable();

  constructor( ) {}



  getCart(): any[] {
    return this.cartSubject.value;
  }

  addToCart(item: any, quantity: number) {
    const cart = this.getCart();
    const itemInCart = cart.find(
      (cartItem) => cartItem.ProductId === item.ProductId
    );
    if (!itemInCart) {
      cart.push({
        ...item,
        count: quantity,
        total: item.price * quantity,
        price: item.price,
      }); // Calculate and store the total price
    } else {
      itemInCart.count += quantity;
      itemInCart.total = itemInCart.count * item.price; // Update the total price
    }
    this.cartSubject.next(cart);
  }

  removeFromCart(item: any) {
    const cart = this.getCart();
    const itemInCart = cart.find(
      (cartItem) => cartItem.ProductId === item.ProductId
    );
    if (itemInCart) {
      if (itemInCart.count === 1) {
        const index = cart.indexOf(itemInCart);
        cart.splice(index, 1);
      } else {
        itemInCart.count--;
        itemInCart.total = itemInCart.count * itemInCart.price; // Update the total price
      }
      this.cartSubject.next(cart);
    }
  }

  removeFromCartItem(item: any) {
    const cart = this.getCart();
    const index = cart.findIndex(
      (cartItem) => cartItem.ProductId === item.ProductId
    );
    if (index !== -1) {
      cart.splice(index, 1);
      this.cartSubject.next(cart);
    }
  }

  getItemCount(productId: number): number {
    const cart = this.getCart();
    const itemInCart = cart.find(
      (cartItem) => cartItem.ProductId === productId
    );
    
    return itemInCart ? itemInCart.count : 0;
  }

  isInCart(item: any): boolean {
    const cart = this.getCart();
    return cart.some((cartItem) => cartItem.ProductId === item.ProductId);
  }

  clearCart() {
    this.cartSubject.next([]);
  }

  updateCartItemQuantity(productId: number, newQuantity: number) {
    const cart = this.getCart();
    const itemInCart = cart.find(
      (cartItem) => cartItem.ProductId === productId
    );

    if (itemInCart) {
      itemInCart.count = newQuantity;
      itemInCart.total = newQuantity * itemInCart.price;
      this.cartSubject.next(cart);
    }
  }

  // addToCart(item: any, quantity: number) {
  //   const cart = this.getCart();
  //   const itemInCart = cart.find(
  //     (cartItem) => cartItem.ProductId === item.ProductId
  //   );
  //   if (!itemInCart) {
  //     cart.push({ ...item, count: quantity });
  //   } else {
  //     itemInCart.count += quantity;
  //   }
  //   this.cartSubject.next(cart);
  // }

  // removeFromCart(item: any) {
  //   const cart = this.getCart();
  //   const itemInCart = cart.find(
  //     (cartItem) => cartItem.ProductId === item.ProductId
  //   );
  //   if (itemInCart) {
  //     if (itemInCart.count === 1) {
  //       const index = cart.indexOf(itemInCart);
  //       cart.splice(index, 1);
  //     } else {
  //       itemInCart.count--;
  //     }
  //     this.cartSubject.next(cart);
  //   }
  // }
  // getItemCount(productId: number): number {
  //   const cart = this.getCart();
  //   const itemInCart = cart.find(
  //     (cartItem) => cartItem.ProductId === productId
  //   );
  //   return itemInCart ? itemInCart.count : 0;
  // }
  // isInCart(item: any): boolean {
  //   const cart = this.getCart();
  //   return cart.some((cartItem) => cartItem.ProductId === item.ProductId);
  // }
  // clearCart() {
  //   this.cartSubject.next([]);
  // }
}
