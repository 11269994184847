import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ProductsService } from "../product.service";
import { NgxSpinnerService } from "ngx-spinner";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "../../../../../src/environments/environment";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material";
import { take } from "rxjs/operators";
import Swal from 'sweetalert2';
import { Router } from "@angular/router";
@Component({
	selector: "kt-product-pricing",
	templateUrl: "./product-pricing.component.html",
	styleUrls: ["./product-pricing.component.scss"],
})
export class ProductPricingComponent implements OnInit {
	checkboxes: any[];
	tableData = [];
	selectData: any[] = [];
	selectOptions: any = {};
	loading = false;
	loadingSubmit = false;
	loadingTable = false;
	productpricingname: string;
	productSearchForm: FormGroup;
	products: any[] = [
		{ name: "Product 1", id: 1, price: null },
		{ name: "Product 2", id: 2, price: null },
		{ name: "Product 3", id: 3, price: null },
		// Add more products as needed
	];
	totalLength: number = 119;
	pageIndex: number = 1;
	pageSize: number = 1000;
	MasterId: number = 0;
	totalLengthEmitter$ = new BehaviorSubject<number>(this.totalLength);
	usersList$: Observable<any[]>;
	private usersListSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
	selectOptionsConfig: any = {};
	selectedProductDataList: any[] = [];


	constructor(private _service: ProductsService, private spinner: NgxSpinnerService, private formBuilder: FormBuilder, private _snackBar: MatSnackBar, private ref: ChangeDetectorRef, private fb: FormBuilder,
		private router:Router
	) {
		this.usersList$ = this.usersListSubject.asObservable();
		this.productpricingname = "";

		var userdata = JSON.parse(localStorage.getItem("user"));
		this.loading = false;
		this.selectOptionsConfig = {
			ajax: {
				url: environment.BaseUrl + "api/Owner/allDropdown",
				dataType: "json",
				delay: 250,
				headers: {
					Authorization: "Bearer " + userdata.token, // Replace 'token' with your actual authorization token
				},
				data: (params: any) => {
					return {
						searchTerm: params.term,
						page: params.page,
						type: 5,
						parentId: 0,
					};
				},
				processResults: (data: any) => {
					this.loading = false;
					const results = data.map((item: any) => {
						return {
							id: item.id,
							text: item.text,
						};
					});

					return {
						results: results,
					};
				},
				cache: true,
			},
			placeholder: "Select a Site",
			minimumInputLength: 0,
		};
	}

	onSubmit() {
		if (this.productSearchForm.invalid) {
			Object.values(this.productSearchForm.controls).forEach((control) => control.markAsTouched());
			this.loading = false;
			return;
		}
		this.loadingSubmit = true;
		const categoryid = this.productSearchForm.get("categoryid").value;
		this.getData(categoryid);
	}

	saveTableData() {
		this.loading = true;

		this.usersList$.pipe(take(1)).subscribe((usersList) => {
			// Filter out products with invalid prices
			const isSelectProducts = usersList.filter((product) => product.isSelected);
			if(isSelectProducts.length>0){
				//const validProducts = usersList.filter((product) => !this.isInvalidPrice(product));

				// Prepare the data to be saved
				const dataToSave = {
					ProductsPricingId: "0",
					productpricingname: this.productpricingname,
					products: isSelectProducts.map((product) => ({
						id: product.ProductId,
						price: product.price.toString(),
					})),
				};
// Assuming you have a service or API call to save the table data
			// Replace 'saveTableDataService' with your actual service or API call
			this._service.ProductsPriceSave(dataToSave).subscribe(
				(response) => {
					// Handle the response if needed
					console.log("Table data saved successfully!");
					this.loading = false;
					this._snackBar.open("Saved Successfully", "Undo", {
						duration: 3000,
					});
				},
				(error) => {
					// Handle the error if needed
					console.error("Failed to save table data:", error);
					this.loading = false;
					this._snackBar.open("Saved Successfully", "Undo", {
						duration: 3000,
					});
				}
			);
			this.router.navigate(["/coffee/product-pricing-data"]);

			}
			else{
				Swal.fire('Error', 'Please Select Product', 'error');
			}
			
			
			
		});
	}

	ngOnInit() {
		//this.getData("0");

		this.productSearchForm = this.fb.group({
			categoryid: ["", Validators.compose([Validators.required])],
		});
		this._service.dropdownAll("", "1", "5", "0").subscribe((data) => {
			this.checkboxes = data;
		});
	}
	resetform() {
		this.productSearchForm.reset();
		// this.getData("0");
	}
	isInvalidPrice(product: any): boolean {
		return product.price === null || product.price === undefined || product.price === "" || !/^\d+$/.test(product.price) || product.price.length > 6;
	}
	getData(categroyid: any[]) {
		this.loadingTable = true;
		const startIndex = this.pageIndex;
		const pageSize = this.pageSize;
		const searchBy = "";
		const searchCriteria = "";
		const sortProperty ="";
		const orderBy="";
		this.spinner.show();
		this._service.productsGET(startIndex, pageSize, searchBy, searchCriteria, categroyid,sortProperty,orderBy).subscribe((response: any[]) => {
			
			let selectedProductIds = this.selectedProductDataList.map(p => p.ProductId);
      response.forEach(p => {
        if(selectedProductIds.includes(p.ProductId)) {
          const selectedProduct = this.selectedProductDataList.find(sp => sp.ProductId === p.ProductId);
          console.log("selectedProduct::", selectedProduct);
          p.price = selectedProduct.price;
          p.isSelected = true;
        } else {
          p.isSelected = false;
        }
      })     
      
      console.log("response after update:::",response);
      
			this.usersListSubject.next(response);
			this.spinner.hide();
			this.loading = false;
			this.loadingSubmit = false;
			this.loadingTable = false;
			this.ref.detectChanges();
		});
	}
	checkboxChanged() {
		this.tableData = this.checkboxes
			.filter((checkbox) => checkbox.checked)
			.map((checkbox) => {
				// Return data related to the checked checkbox
				return {
					id: checkbox.id,
					text: checkbox.text,
				};
			});
		this.getData(this.tableData);
		// console.log(this.tableData);
	}

	onProductValueChange(item:any,value:any){
		console.log("Checked::",value.target.checked);
		console.log("item::",item);
		if(value.target.checked==true){
		  this.selectedProductDataList.push(item);
		}
		else{
			const index = this.selectedProductDataList.findIndex(p=>p.ProductId==item.ProductId);
			this.selectedProductDataList.splice(index,1);
		}
		
	
		console.log("SelectedProductData::",this.selectedProductDataList);
		
	
	  }
	
	  onAmountChange(item:any,Price:string){
		console.log("Checked::",Price);
		console.log("item::",item);
		const parsedPrice = parseInt(Price, 10);
		if (!isNaN(parsedPrice) && parsedPrice >= 0) {
		  const index = this.selectedProductDataList.findIndex(p=>p.ProductId==item.ProductId);
		  if(index>=0){
			if (parsedPrice === 0) {
			  // If the quantity is 0, remove the item from the cart
			 // this.selectedProductDataList.splice(index,1);
			} else {
			  // Otherwise, update the quantity using the cart service
			 this.selectedProductDataList[index].price=parsedPrice;
			}
		  }
		  
		}
	
		console.log("SelectedProductData::",this.selectedProductDataList);
		
	
	  }
	  onBtnCancel(){
		this.router.navigate(["/coffee/product-pricing-data"]);
	  }
	
}
