import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { BehaviorSubject, Observable } from "rxjs";
import { CoffeeService } from "../../coffee.service";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'kt-view-rpt-pending-order-category',
  templateUrl: './view-rpt-pending-order-category.component.html',
  styleUrls: ['./view-rpt-pending-order-category.component.scss']
})
export class ViewRptPendingOrderCategoryComponent implements OnInit {

  customerForm: FormGroup;
	hasFormErrors = false;
	viewLoading = false;
	loading = false;
	ordersList$: Observable<any[]>;
	private ordersListSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

	order_no: String;
	order_date: String;
	order_id: String;
	orderValue: String;
	company_name: String;
	order_status: String;
  totPendingOrder:String;

  constructor(public dialogRef: MatDialogRef<ViewRptPendingOrderCategoryComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, private coffeeService: CoffeeService, private spinner: NgxSpinnerService) {
		const owner_company_id = this.data.orderId;
		this.ordersList$ = this.ordersListSubject.asObservable();

    this.PendingOrdersGETCategorywise(null, null, owner_company_id, null,"0");
		
		console.log("Received Order ID:", owner_company_id);
	}

  ngOnInit() {
    this.createForm();

  }

  OrdersDetailsGET(order_id) {
		this.spinner.show();
		this.coffeeService.OrdersDetailsGET(order_id).subscribe((response: any[]) => {
			this.ordersListSubject.next(response);
			this.spinner.hide();
			this.orderValue = response[0].order_value;
			this.order_no = response[0].order_no;
			this.order_date = response[0].order_date;
			this.company_name = response[0].company_name;
			this.order_status = response[0].order_status;
		});
	}

  PendingOrdersGETCategorywise(fromDate: string, toDate: string, owner_company_id: string,orderno: string,CategoryID:string) {
		const startIndex = 1; // Set your desired values for the parameters
		const pageSize = 100;
		const searchBy = "";
		const searchCriteria = "";
		this.spinner.show();

    
		
		this.coffeeService.PendingOrdersGETCategorywise(startIndex, pageSize, searchBy, searchCriteria, fromDate, toDate, owner_company_id, orderno,CategoryID).subscribe((response: any[]) => {
			this.ordersListSubject.next(response);
      // this.columnHeader=Object.keys(response[0]);
			// this.deletecolumnHeader("owner_company_id");
      this.company_name = response[0].company_Name;
      console.log("DATA>>:",response);
			this.spinner.hide();
			
      this.getTotalAmount(response);
     

		});
	}

  getTotalAmount(Data : any){
    let totAmount = 0;
    let totPendingQty=0;
    Data.forEach((item) => {
        if(item.Amount != '0'){
          totAmount += item.Amount
        }
        if(item.pending_qty != '0'){
          totPendingQty += item.pending_qty
      }
        
    }); 
    
    this.orderValue=totAmount.toString();
    this.totPendingOrder=totPendingQty.toString();

  }



  createForm() {
		this.customerForm = this.fb.group({
			firstName: [" ", Validators.required],
			lastName: ["", Validators.required],
			email: ["", Validators.compose([Validators.required, Validators.email])],
			dob: ["", Validators.compose([Validators.nullValidator])],
			userName: ["", Validators.compose([Validators.required])],
			gender: ["", Validators.compose([Validators.required])],
			ipAddress: ["", Validators.compose([Validators.required])],
			type: ["", Validators.compose([Validators.required])],
		});
  }

  getTitle(): string {
		return "View Pending Order";
	}

  print():void{
		
		window.print();
	}

}
