import { ChangeDetectorRef, AfterViewInit, Component, ElementRef, OnInit, ViewChild,NgModule } from "@angular/core";
import { MatDialog, MatSnackBar } from "@angular/material";
import { BehaviorSubject, Observable } from "rxjs";
import { CoffeeService } from "../../coffee.service";
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from "../../../../../src/environments/environment";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DatePipe } from "@angular/common";
import{NgSelect2Component,NgSelect2Module,Select2OptionData} from "ng-select2";
import { ViewRptPendingOrderCategoryComponent } from "../../Modals/view-rpt-pending-order-category/view-rpt-pending-order-category.component";

@Component({
  selector: 'kt-rpt-pending-order-category',
  templateUrl: './rpt-pending-order-category.component.html',
  styleUrls: ['./rpt-pending-order-category.component.scss']
})
export class RptPendingOrderCategoryComponent implements OnInit {

  ordersList$: Observable<any[]>;
  reportForm: FormGroup;
  pageIndex: number = 1;
	pageSize: number = 20;
  data$: Observable<any[]>;

  orderValue: String;

  columnHeader=[];
CategoryID:number=0;

  private ordersListSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);


  constructor(private dialog: MatDialog, private fb: FormBuilder,private datePipe: DatePipe, public snackBar: MatSnackBar, private ref: ChangeDetectorRef, private coffeeService: CoffeeService, private spinner: NgxSpinnerService) {
    this.ordersList$ = this.ordersListSubject.asObservable();

		var userdata = JSON.parse(localStorage.getItem("user"));

   

   }

  ngOnInit() {

    this.reportForm = this.fb.group({
      CategoryID: [""],
  
  });
  this.PendingOrdersGETCategorywise(null, null, null, null,"0");


  }

  PendingOrdersGETCategorywise(fromDate: string, toDate: string, owner_company_id: string,orderno: string,CategoryID:string) {
		const startIndex = this.pageIndex; // Set your desired values for the parameters
		const pageSize = this.pageSize;
		const searchBy = "";
		const searchCriteria = "";
		this.spinner.show();

    
		
		this.coffeeService.PendingOrdersGETCategorywise(startIndex, pageSize, searchBy, searchCriteria, fromDate, toDate, owner_company_id, orderno,CategoryID).subscribe((response: any[]) => {
			this.ordersListSubject.next(response);
      this.columnHeader=Object.keys(response[0]);
			this.deletecolumnHeader("owner_company_id");
      console.log("DATA>>:",response);
			this.spinner.hide();
			this.getTotalAmount(response);

			console.log("ResponseCount>>:",Object.keys(response).length);
		}, 
		(error) => {
			console.log("Response error::>>",error);
			
		  }
	
	);
	}

  deletecolumnHeader(msg:string) {
    const index: number = this.columnHeader.indexOf(msg);
    if (index !== -1) {
        this.columnHeader.splice(index, 1);
    }        
}

getTotalAmount(Data : any){
	
    let totAmount = 0;
    let totPendingQty=0;
	
    Data.forEach((item) => {
		if(item.Amount != '0'){
			totAmount += parseFloat(item.Amount)
		  }
		  
    }); 
	this.orderValue=totAmount.toString()+" /-";
	console.log("totAmount>>:",totAmount);


}


  getSelectOptionsConfig(): any {
		var userdata = JSON.parse(localStorage.getItem("user"));
		return {
			ajax: {
				url: environment.BaseUrl + "api/Owner/allDropdown",
				dataType: "json",
				delay: 250,
				headers: {
					Authorization: "Bearer " + userdata.token,
				},
				data: (params: any) => {
					return {
						searchTerm: params.term,
						page: params.page,
						type: 5,
						parentId: "0",
					};
				},
				processResults: (data: any) => {
					const results = data.map((item: any) => {
						return {
							id: item.id,
							text: item.text,
						};
					});

					results.splice(0,0,{id: 0,text: "Show All"});

					return {
						results: results,
					};
				},
				cache: true,
			},
			placeholder: "Select a Category",
			minimumInputLength: 0,
		};
	}

  ViewData(value:string){
console.log("ValueID",value);
  }

  

  resetform() {
		this.reportForm.reset();
		this.PendingOrdersGETCategorywise(null, null, null, null,"0");
	}

  onValueChange(value: any){
    console.log("Selected>::",value);
    if(value!=''){
      this.CategoryID=value;
    //   --comment
	this.orderValue="";
      this.PendingOrdersGETCategorywise(null, null, null, null,value);
    }  
   
 }

 openModal(owner_company_id: number) {
	console.log("OwnID>>::",owner_company_id);
	const dialogRef = this.dialog.open(ViewRptPendingOrderCategoryComponent, {
		data: { orderId: owner_company_id },
	});

	// You can handle dialog events here if needed
	dialogRef.afterClosed().subscribe((result) => {
		console.log("Dialog closed with result:", result);
	});
	
 }


}
