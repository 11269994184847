import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
// import { ProductsService } from "../product.service";
import { NgxSpinnerService } from "ngx-spinner";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "../../../../../src/environments/environment";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material";
import { take } from "rxjs/operators";
import Swal from 'sweetalert2';
import { Router } from "@angular/router";
import { CoffeeService } from "../../coffee.service";

@Component({
  selector: 'kt-sales-manager-region-access',
  templateUrl: './sales-manager-region-access.component.html',
  styleUrls: ['./sales-manager-region-access.component.scss']
})
export class SalesManagerRegionAccessComponent implements OnInit {

  loading = false;
	loadingSubmit = false;
	loadingTable = false;

  RegionData: any[] = [];
	RegionOptions: any = {};
	RegionOptionsConfig: any = {};
  RegionId:string = "0";

  usersList$: Observable<any[]>;
	private usersListSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  selectedUserDataList: any[] = [];

  constructor(public snackBar: MatSnackBar, private ref: ChangeDetectorRef, private coffeeService: CoffeeService, private spinner: NgxSpinnerService,private _snackBar: MatSnackBar) {
    this.usersList$ = this.usersListSubject.asObservable();

    var userdata = JSON.parse(localStorage.getItem("user"));

    this.RegionOptionsConfig = {
			ajax: {
				url: environment.BaseUrl + "api/Owner/allDropdown",
				dataType: "json",
				delay: 250,
				headers: {
					Authorization: "Bearer " + userdata.token,
				},
				data: (params: any) => {
					return {
						searchTerm: params.term,
						page: params.page,
						type: 17,
						parentId: 0,
					};
				},
				processResults: (data: any) => {
					const results = data.map((item: any) => {
						return {
							id: item.id,
							text: item.text,
						};
					});
					results.splice(0,0,{id: 0,text: "Select"});
					return {
						results: results,
					};
				},
				cache: true,
			},
			placeholder: "Select a Region",
			minimumInputLength: 0,
		};


   }



  ngOnInit() {
  }


  getData(RegionId: string){
    this.loadingTable = true;
    this.spinner.show();
    this.coffeeService.UsersInRegionsGET(RegionId).subscribe((response: any[]) =>{
     
      this.usersListSubject.next(response);
      console.log("response:::>",response);
      this.selectedUserDataList=response;
			this.spinner.hide();
			this.loading = false;
			this.loadingSubmit = false;
			this.loadingTable = false;

    });

  }

  RegionChanged(regionID :string){
    this.RegionId=regionID;
    this.getData(regionID);
  }

  onUserValueChange(item:any,value:any){
    console.log("Checked::",value.target.checked);
    console.log("item::",item);
    if(value.target.checked==true){
      let selectedUserIds = item.UserId; 
 const index = this.selectedUserDataList.findIndex(p=>p.ProductId==item.UserId);
        if(index>=0)
        {
            this.selectedUserDataList[index].IsActive="1";
        }
    }
  else{
    const index = this.selectedUserDataList.findIndex(p=>p.ProductId==item.UserId);
        if(index>=0)
        {
            this.selectedUserDataList[index].IsActive="0";
        }
    }
    console.log("selectedUserDataList::>>",this.selectedUserDataList);
  }
  saveTableData(){

console.log("RegionId::>>",this.RegionId);
if(this.RegionId!="0")
{
  this.loading = true;
  const selectedUsers = this.selectedUserDataList;
  if(selectedUsers.length>0){
    const usersToSave = {
      RegionId: this.RegionId.toString(),
      users: selectedUsers.map((user) => ({
        UserId: user.UserId,
        UserName:user.UserName,
        IsActive: user.IsActive==true?1:0,
      })),
      
    };
    debugger
    console.log("usersToSave::>>",usersToSave);
    this.coffeeService.UsersInRegionsInsertUpdate(usersToSave).subscribe(
      (response) => {
        // Handle the response if needed
        console.log("Table data saved successfully!");
        this.loading = false;
        this._snackBar.open("Saved Successfully", "Undo", {
          duration: 3000,
        });
      },
      (error) => {
        // Handle the error if needed
        console.error("Failed to save table data:", error);
        this.loading = false;
        this._snackBar.open("Saved Successfully", "Undo", {
          duration: 3000,
        });

      }
      );
      



  }

}
else{

}
  }
  onBtnCancel(){

  }

}
