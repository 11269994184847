import { ChangeDetectorRef, AfterViewInit, Component, ElementRef, OnInit, ViewChild,NgModule } from "@angular/core";
import { MatDialog, MatSnackBar } from "@angular/material";
import { BehaviorSubject, Observable } from "rxjs";
import { CoffeeService } from "../../coffee.service";
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from "../../../../../src/environments/environment";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DatePipe } from "@angular/common";
import{NgSelect2Component,NgSelect2Module,Select2OptionData} from "ng-select2";
import { result } from "lodash";

@Component({
  selector: 'kt-rpt-pending-order-value',
  templateUrl: './rpt-pending-order-value.component.html',
  styleUrls: ['./rpt-pending-order-value.component.scss']
})
export class RptPendingOrderValueComponent implements OnInit {

 
	
	
	loading = false;
	
	
	ordersList$: Observable<any[]>;

  

  CompanyData$: Observable<any[]>;
  CompanyOptions: any = {};
	CompanyOptionsConfig: any = {};

  CategoryData: any[] = [];
  CategoryOptions: any = {};
	CategoryOptionsConfig: any = {};

  TypeValue =12;
  SearchValue =0;

  RoleID=0;
  RegionType =17;
  UserID =0;

	reportForm: FormGroup;
	loadingSubmit = false;
	private ordersListSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
	constructor(private dialog: MatDialog, private fb: FormBuilder,private datePipe: DatePipe, public snackBar: MatSnackBar, private ref: ChangeDetectorRef, private coffeeService: CoffeeService, private spinner: NgxSpinnerService) {
		this.ordersList$ = this.ordersListSubject.asObservable();

		var userdata = JSON.parse(localStorage.getItem("user"));
		this.RoleID = userdata.roleId;

		if(this.RoleID==6)
		{
			this.RegionType=21;
			this.UserID=userdata.id;
			this.TypeValue=22;
			this.SearchValue=this.UserID;
		}

		 this.CategoryOptionsConfig = {
     
        ajax: {
          url: environment.BaseUrl + "api/Owner/allDropdown",
          dataType: "json",
          delay: 250,
          headers: {
            Authorization: "Bearer " + userdata.token,
          },
          data: (params: any) => {
            return {
              searchTerm: params.term,
              page: params.page,
              type: 5,
              parentId: 0,
            };
          },
          processResults: (data: any) => {
            const results = data.map((item: any) => {
              return {
                id: item.id,
                text: item.text,
              };
            });
            results.splice(0,0,{id: 0,text: "Show All"});
            return {
              results: results,
            };
          },
          cache: true,
        },
        placeholder: "Select a Category",
        minimumInputLength: 0,
      };

   
    this.CompanyOptionsConfig = {
     
			ajax: {
				url: environment.BaseUrl + "api/Owner/allDropdown",
				dataType: "json",
				delay: 250,
				headers: {
					Authorization: "Bearer " + userdata.token,
				},
				data: (params: any) => {
					return {
            searchTerm: params.term,
            page: params.page,
            type: this.TypeValue,
            parentId: this.SearchValue,
					};
				},
				processResults: (data: any) => {
					const results = data.map((item: any) => {
						return {
							id: item.id,
							text: item.text,
						};
					});
					results.splice(0,0,{id: 0,text: "Show All"});
					return {
						results: results,
					};
				},
				cache: true,
			},
			placeholder: "Select a Company",
			minimumInputLength: 0,
		};

	}
	
  ngOnInit() {
		this.reportForm = this.fb.group({
			CategoryId: [""],
      owner_company_id: [""],
			fromdate: ["", Validators.compose([Validators.required])],
			todate: ["", Validators.compose([Validators.required])],
		});

		this.PendingOrderValueGet(null,null,null,null);
	}

  PendingOrderValueGet(fromDate: string, toDate: string,owner_company_id: string,CategoryId: string) {
	
		//this.spinner.show();
		//this.loadingSubmit = true;

		this.coffeeService.PendingOrderValueGet(fromDate,toDate,owner_company_id,CategoryId).subscribe((response: any[]) => {
			this.ordersListSubject.next(response);
			//this.loadingSubmit = false;		
			//this.spinner.hide();
			//this.loadingSubmit = false;

      console.log(response);
			
		});
	}

  onSubmit(){
		if (this.reportForm.invalid) {
			Object.values(this.reportForm.controls).forEach((control) => control.markAsTouched());
			return;
		}
		const owner_company_id = this.reportForm.get("owner_company_id").value;
    const CategoryId = this.reportForm.get("CategoryId").value;
  	const fromDateValue = this.datePipe.transform(this.reportForm.get("fromdate").value, "MM-dd-yyyy");
		const toDateValue = this.datePipe.transform(this.reportForm.get("todate").value, "MM-dd-yyyy");	
		this.PendingOrderValueGet(fromDateValue,toDateValue,owner_company_id,CategoryId);
	}
	resetform() {
		this.reportForm.reset();
		this.PendingOrderValueGet(null,null,null,null);
	}

    onCategoryChange(value:any)
  {
    const owner_company_id = this.reportForm.get("owner_company_id").value;
    const CategoryId = value;
  	const fromDateValue = this.datePipe.transform(this.reportForm.get("fromdate").value, "MM-dd-yyyy");
		const toDateValue = this.datePipe.transform(this.reportForm.get("todate").value, "MM-dd-yyyy");	
		this.PendingOrderValueGet(fromDateValue,toDateValue,owner_company_id,CategoryId);
  }
  onCompanyChange(value:any)
  {
    const owner_company_id = value;
    const CategoryId = this.reportForm.get("CategoryId").value;
  	const fromDateValue = this.datePipe.transform(this.reportForm.get("fromdate").value, "MM-dd-yyyy");
		const toDateValue = this.datePipe.transform(this.reportForm.get("todate").value, "MM-dd-yyyy");	
		this.PendingOrderValueGet(fromDateValue,toDateValue,owner_company_id,CategoryId);
  }

  OnCategoryClick(value:any){
	const owner_company_id = this.reportForm.get("owner_company_id").value;
	const CategoryId = value;
	const fromDateValue = this.datePipe.transform(this.reportForm.get("fromdate").value, "MM-dd-yyyy");
	const toDateValue = this.datePipe.transform(this.reportForm.get("todate").value, "MM-dd-yyyy");	
	this.PendingOrderValueGet(fromDateValue,toDateValue,owner_company_id,CategoryId);
   }

}
