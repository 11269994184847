import { ChangeDetectorRef, AfterViewInit, Component, ElementRef, OnInit, ViewChild,NgModule } from "@angular/core";
import { MatDialog, MatSnackBar } from "@angular/material";
import { BehaviorSubject, Observable } from "rxjs";
import { CoffeeService } from "../../coffee.service";
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from "../../../../../src/environments/environment";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DatePipe } from "@angular/common";
import{NgSelect2Component,NgSelect2Module,Select2OptionData} from "ng-select2";
import { result } from "lodash";
import Swal from 'sweetalert2';

@Component({
  selector: 'kt-rpt-pending-order-company',
  templateUrl: './rpt-pending-order-company.component.html',
  styleUrls: ['./rpt-pending-order-company.component.scss']
})
export class RptPendingOrderCompanyComponent implements OnInit {

  totalLength: number = 119;
	pageIndex: number = 1;
	pageSize: number = 10;
	
	//Pagination
	totalPages: number = 50;
	currentPage: number = 1;
	totalRecords: number = 0;
	itemsPerPage = 10;
	totalItems = 0;
	searchQuery: string = "";
	loading = false;
	
	// New Check Sorting
	SortColumn: string = 'order_no';
    OrderSort = 1;
    SortOrder:string="ASC";

	ordersList$: Observable<any[]>;

	RegionData: any[] = [];
	RegionOptions: any = {};
	RegionOptionsConfig: any = {};
  RegionId = 0;
  StateId=0;


  StateData$: Observable<any[]>;
  StateOptions: any = {};
	StateOptionsConfig: any = {};
  

  CompanyData$: Observable<any[]>;
  CompanyOptions: any = {};
	CompanyOptionsConfig: any = {};

	CategoryData: any[] = [];
	CategoryOptions: any = {};
	CategoryOptionsConfig: any = {};

  TypeValue =12;
  SearchValue =0;

  RoleID=0;
  RegionType =17;
  UserID =0;
  CancelStatus:boolean=false;
  owner_company_id:number=0;

	reportForm: FormGroup;
	loadingSubmit = false;
	private ordersListSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
	constructor(private dialog: MatDialog, private fb: FormBuilder,private datePipe: DatePipe, public snackBar: MatSnackBar, private ref: ChangeDetectorRef, private coffeeService: CoffeeService, private spinner: NgxSpinnerService) {
		this.ordersList$ = this.ordersListSubject.asObservable();
		
		var userdata = JSON.parse(localStorage.getItem("user"));
		this.RoleID = userdata.roleId;

		if(this.RoleID==1 && this.owner_company_id>0)
		{
			this.CancelStatus=false;
		}

		if(this.RoleID==6)
		{
			this.RegionType=21;
			this.UserID=userdata.id;
			this.TypeValue=22;
			this.SearchValue=this.UserID;
		}

		this.RegionOptionsConfig = {
			ajax: {
				url: environment.BaseUrl + "api/Owner/allDropdown",
				dataType: "json",
				delay: 250,
				headers: {
					Authorization: "Bearer " + userdata.token,
				},
				data: (params: any) => {
					return {
						searchTerm: params.term,
						page: params.page,
						type: this.RegionType,
						parentId: this.UserID,
					};
				},
				processResults: (data: any) => {
					const results = data.map((item: any) => {
						return {
							id: item.id,
							text: item.text,
						};
					});
					results.splice(0,0,{id: 0,text: "Show All"});
					return {
						results: results,
					};
				},
				cache: true,
			},
			placeholder: "Select a Region",
			minimumInputLength: 0,
		};

    this.StateOptionsConfig = {
			ajax: {
				url: environment.BaseUrl + "api/Owner/allDropdown",
				dataType: "json",
				delay: 250,
				headers: {
					Authorization: "Bearer " + userdata.token,
				},
				data: (params: any) => {
					return {
						searchTerm: params.term,
						page: params.page,
						type: 18,
						parentId: this.RegionId,
					};
				},
				processResults: (data: any) => {
					const results = data.map((item: any) => {
						return {
							id: item.id,
							text: item.text,
						};
					});
					results.splice(0,0,{id: 0,text: "Show All"});
					return {
						results: results,
					};
				},
				cache: true,
			},
			placeholder: "Select a State",
			minimumInputLength: 0,
		};

    this.CompanyOptionsConfig = {
     
			ajax: {
				url: environment.BaseUrl + "api/Owner/allDropdown",
				dataType: "json",
				delay: 250,
				headers: {
					Authorization: "Bearer " + userdata.token,
				},
				data: (params: any) => {
					return {
            searchTerm: params.term,
            page: params.page,
            type: this.TypeValue,
            parentId: this.SearchValue,
					};
				},
				processResults: (data: any) => {
					const results = data.map((item: any) => {
						return {
							id: item.id,
							text: item.text,
						};
					});
					results.splice(0,0,{id: 0,text: "Show All"});
					return {
						results: results,
					};
				},
				cache: true,
			},
			placeholder: "Select a Company",
			minimumInputLength: 0,
		};

		this.CategoryOptionsConfig = {
     
			ajax: {
			  url: environment.BaseUrl + "api/Owner/allDropdown",
			  dataType: "json",
			  delay: 250,
			  headers: {
				Authorization: "Bearer " + userdata.token,
			  },
			  data: (params: any) => {
				return {
				  searchTerm: params.term,
				  page: params.page,
				  type: 5,
				  parentId: 0,
				};
			  },
			  processResults: (data: any) => {
				const results = data.map((item: any) => {
				  return {
					id: item.id,
					text: item.text,
				  };
				});
				results.splice(0,0,{id: 0,text: "Show All"});
				return {
				  results: results,
				};
			  },
			  cache: true,
			},
			placeholder: "Select a Category",
			minimumInputLength: 0,
		  };
	

	}
	
  ngOnInit() {
		this.reportForm = this.fb.group({
			RegionId: ["0"],
      StateId: ["0"],
      owner_company_id: ["0"],
	  CategoryId: ["0"],
			fromdate: ["", Validators.compose([Validators.required])],
			todate: ["", Validators.compose([Validators.required])],
		});

		this.CompanyWiseOrdersGET(null,null,null,null,null,null);
	}

  CompanyWiseOrdersGET(fromDate: string, toDate: string,owner_company_id: string,RegionId: string,StateId:string,CategoryId:string) {
		const startIndex = this.pageIndex; // Set your desired values for the parameters
		const pageSize = this.pageSize;
		
		this.spinner.show();
		this.loadingSubmit = true;

		const SortColumn = this.SortColumn;
		const SortOrder=this.SortOrder;
	

		this.coffeeService.CompanyWiseOrdersGET(startIndex, pageSize, fromDate,toDate,owner_company_id,RegionId,StateId,CategoryId,SortColumn,SortOrder).subscribe((response: any[]) => {
			this.ordersListSubject.next(response);
			this.loadingSubmit = false;
			this.totalPages = response[0].TotalPages;
			this.totalRecords = response[0].TotalRecords;
			this.totalLength =response[0].TotalRecords;
			this.spinner.hide();
			this.loadingSubmit = false;

			this.CancelStatusCall();

      console.log("responseData::>>",response);
			
		});
	}

  onSubmit(){
		if (this.reportForm.invalid) {
			Object.values(this.reportForm.controls).forEach((control) => control.markAsTouched());
			return;
		}
		const owner_company_id = this.reportForm.get("owner_company_id").value;
    const RegionId = this.reportForm.get("RegionId").value;
    const StateId = this.reportForm.get("StateId").value;
	const CategoryId=this.reportForm.get("CategoryId").value;
		const fromDateValue = this.datePipe.transform(this.reportForm.get("fromdate").value, "MM-dd-yyyy");
		const toDateValue = this.datePipe.transform(this.reportForm.get("todate").value, "MM-dd-yyyy");	
		this.CompanyWiseOrdersGET(fromDateValue,toDateValue,owner_company_id,RegionId,StateId,CategoryId);
	}
	resetform() {
		this.reportForm.reset();
		this.CompanyWiseOrdersGET(null,null,null,null,null,null);
		this.owner_company_id=0;
	}

  

  onPageChanged(e) {
		console.log("pageIndex>>::",this.pageIndex+"pageSize>>:",this.pageSize);
		this.pageIndex = e.pageIndex + 1;
		this.pageSize = e.pageSize;
		console.log("pageIndex>>::",this.pageIndex+"pageSize>>:",this.pageSize);
		const owner_company_id = this.reportForm.get("owner_company_id").value;
    const RegionId = this.reportForm.get("RegionId").value;
    const StateId = this.reportForm.get("StateId").value;
	const CategoryId=this.reportForm.get("CategoryId").value;
		const fromDateValue = this.datePipe.transform(this.reportForm.get("fromdate").value, "MM-dd-yyyy");
		const toDateValue = this.datePipe.transform(this.reportForm.get("todate").value, "MM-dd-yyyy");	
		this.CompanyWiseOrdersGET(fromDateValue,toDateValue,owner_company_id,RegionId,StateId,CategoryId);
	}
	onPageChange(page) {
		this.currentPage = page;		
		this.pageIndex = page;
		//this.pageSize = page.pageSize;		
    const owner_company_id = this.reportForm.get("owner_company_id").value;
    const RegionId = this.reportForm.get("RegionId").value;
    const StateId = this.reportForm.get("StateId").value;
	const CategoryId=this.reportForm.get("CategoryId").value;
		const fromDateValue = this.datePipe.transform(this.reportForm.get("fromdate").value, "MM-dd-yyyy");
		const toDateValue = this.datePipe.transform(this.reportForm.get("todate").value, "MM-dd-yyyy");	
		this.CompanyWiseOrdersGET(fromDateValue,toDateValue,owner_company_id,RegionId,StateId,CategoryId);
	}
	onPageSizeChange(page) {
		
		console.log("currentPage>>:",this.currentPage);
		// this.currentPage = page;
		this.pageSize = page;
		this.pageIndex = 1;	
		
		const owner_company_id = this.reportForm.get("owner_company_id").value;
    const RegionId = this.reportForm.get("RegionId").value;
    const StateId = this.reportForm.get("StateId").value;
	const CategoryId=this.reportForm.get("CategoryId").value;
		const fromDateValue = this.datePipe.transform(this.reportForm.get("fromdate").value, "MM-dd-yyyy");
		const toDateValue = this.datePipe.transform(this.reportForm.get("todate").value, "MM-dd-yyyy");	
		this.CompanyWiseOrdersGET(fromDateValue,toDateValue,owner_company_id,RegionId,StateId,CategoryId);
	}

	// check sorting
	sortBy(property: string) {
        this.OrderSort = property === this.SortColumn ? (this.OrderSort * -1) : 1;
        this.SortColumn = property;
       
		if(this.OrderSort===1)
		{
			this.SortOrder="ASC";
			console.log("sortOrderASC>>:",this.SortOrder);

		}
		if(this.OrderSort===-1)
			{
				this.SortOrder="DESC";
				console.log("sortOrderDESC>>:",this.SortOrder);
	
			}
		

    const owner_company_id = this.reportForm.get("owner_company_id").value;
    const RegionId = this.reportForm.get("RegionId").value;
    const StateId = this.reportForm.get("StateId").value;
	const CategoryId=this.reportForm.get("CategoryId").value;
		const fromDateValue = this.datePipe.transform(this.reportForm.get("fromdate").value, "MM-dd-yyyy");
		const toDateValue = this.datePipe.transform(this.reportForm.get("todate").value, "MM-dd-yyyy");	
		this.CompanyWiseOrdersGET(fromDateValue,toDateValue,owner_company_id,RegionId,StateId,CategoryId);
    }
	sortIcon(property: string) {
        if (property === this.SortColumn) {
           // return this.sortOrder === 1 ? '▲' : '▼';
			return this.OrderSort === 1 ? '▲' : '▼';
        }
        return '';
    }

    RegionChanged(value:any){
     
      this.RegionId=value;  
     if(value==0)
	 {
		this.TypeValue=12;
	    this.SearchValue=value;
		if(this.RoleID==6)
			{
				
				this.TypeValue=22;
				this.SearchValue=this.UserID;
			}
	
	 }
	 else{
		this.TypeValue=19;
		this.SearchValue=value;
	 }
      
	  console.log("SelectValue>>:",this.SearchValue);
     this.StateOptionsConfig={};  
     this.CompanyOptionsConfig={};  
     this.pageIndex=1;
     this.currentPage=1;
     this.totalPages=0;
     this.reportForm.get('owner_company_id').reset();
     this.reportForm.get('StateId').reset();
     
     
     const owner_company_id = "0";
    const RegionId = value;
    const StateId = "0";
	const CategoryId=this.reportForm.get("CategoryId").value;
		const fromDateValue = this.datePipe.transform(this.reportForm.get("fromdate").value, "MM-dd-yyyy");
		const toDateValue = this.datePipe.transform(this.reportForm.get("todate").value, "MM-dd-yyyy");	
		this.CompanyWiseOrdersGET(fromDateValue,toDateValue,owner_company_id,RegionId,StateId,CategoryId);
    
    }
    StateChanged(value:any){
     
      this.StateId=value; 
      this.TypeValue=20;
      this.SearchValue=value; 
     this.CompanyOptionsConfig={}; 
     this.pageIndex=1;
     this.currentPage=1;
     this.totalPages=0;
     this.reportForm.get('owner_company_id').reset();
     const owner_company_id = "0";
    const RegionId = this.reportForm.get("RegionId").value;
    const StateId = value;
	const CategoryId=this.reportForm.get("CategoryId").value;
		const fromDateValue = this.datePipe.transform(this.reportForm.get("fromdate").value, "MM-dd-yyyy");
		const toDateValue = this.datePipe.transform(this.reportForm.get("todate").value, "MM-dd-yyyy");	
		this.CompanyWiseOrdersGET(fromDateValue,toDateValue,owner_company_id,RegionId,StateId,CategoryId);
    }


    onCompanyChange(value:any){
      this.pageIndex=1;
      this.currentPage=1;
      this.totalPages=0;
      const owner_company_id = value;
      const RegionId = this.reportForm.get("RegionId").value;
      const StateId = this.reportForm.get("StateId").value;
	  const CategoryId=this.reportForm.get("CategoryId").value;
      const fromDateValue = this.datePipe.transform(this.reportForm.get("fromdate").value, "MM-dd-yyyy");
      const toDateValue = this.datePipe.transform(this.reportForm.get("todate").value, "MM-dd-yyyy");
      
      this.CompanyWiseOrdersGET(fromDateValue,toDateValue,owner_company_id,RegionId,StateId,CategoryId);
	  this.owner_company_id=parseInt(value);
	  
    }

	onCategoryChange(value:any)
	{
		this.pageIndex=1;
		this.currentPage=1;
		this.totalPages=0;
		const owner_company_id = this.reportForm.get("owner_company_id").value;
		const RegionId = this.reportForm.get("RegionId").value;
		const StateId = this.reportForm.get("StateId").value;
		const CategoryId=value;
		const fromDateValue = this.datePipe.transform(this.reportForm.get("fromdate").value, "MM-dd-yyyy");
		const toDateValue = this.datePipe.transform(this.reportForm.get("todate").value, "MM-dd-yyyy");
		
		this.CompanyWiseOrdersGET(fromDateValue,toDateValue,owner_company_id,RegionId,StateId,CategoryId);
	}

	CancelStatusCall()
	{
	
		if(this.RoleID==1 && this.owner_company_id>0 )
			{
				this.CancelStatus=true;
			}
			else{
				this.CancelStatus=false;
			}
	}

	onDeleteOrder(item:any){

		console.log("ItemData::>>",item);
		this.deleteOrder(item);

	}


	deleteOrder(order: any) {
		Swal.fire({
		  title: 'Are you sure you want to cancel the Order?',
		  text: "You won't be able to revert this!",
		  icon: 'warning',
		  showCancelButton: true,
		  confirmButtonColor: '#f1416c',
		  cancelButtonColor: '#rgba(0,0,0,0)',
		  confirmButtonText: 'Yes, Cancel this order!',
		}).then((result) => {
		  if (result.isConfirmed) {
			
			const requestBody = {
			  order_id: order.orders_details_id.toString(),
			  order_status_id: '6',
			  UserId: '1',
			};
	
			this.makeApiCall(requestBody);
	
			// this.currentPage = 1;
			setTimeout(()=>{                          
				this.OrdersGET();
			}, 3000);
			
		  } else if (result.dismiss === Swal.DismissReason.cancel) {
			// Handle cancel action
			Swal.fire('Cancelled', 'Your order is safe :)', 'info');
		  }
		});

		
		
	  }

	makeApiCall(requestBody: any) {
		this.coffeeService.OrdersDetailsCancelUpdate(requestBody).subscribe(
		  (response: any) => {
			Swal.fire('Success!', 'Your Order cancelled successfully .', 'success');
		  },
		  (error) => {
			// Handle error response
			console.error('API Error:', error);
	
			// Check the error status or any other criteria to decide the message
			const errorMessage =
			  error && error.message ? error.message : 'An error occurred.';
	
			Swal.fire('Error!', errorMessage, 'error');
		  }
		);
	  }


	  OrdersGET(){ 
	  const owner_company_id = this.reportForm.get("owner_company_id").value;
      const RegionId = this.reportForm.get("RegionId").value;
      const StateId = this.reportForm.get("StateId").value;
	  const CategoryId=this.reportForm.get("CategoryId").value;
      const fromDateValue = this.datePipe.transform(this.reportForm.get("fromdate").value, "MM-dd-yyyy");
      const toDateValue = this.datePipe.transform(this.reportForm.get("todate").value, "MM-dd-yyyy");      
      this.CompanyWiseOrdersGET(fromDateValue,toDateValue,owner_company_id,RegionId,StateId,CategoryId);
	  }


}
