import { ChangeDetectorRef, AfterViewInit, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MatSnackBar } from "@angular/material";
import { BehaviorSubject, Observable } from "rxjs";
import { CoffeeService } from "../../coffee.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ViewOrderComponent } from "../../Modals/view-order/view-order.component";
import { environment } from "../../../../../src/environments/environment";
import { debounceTime, distinctUntilChanged, startWith, switchMap } from "rxjs/operators";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DatePipe } from "@angular/common";
import{NgSelect2Component,NgSelect2Module,Select2OptionData} from "ng-select2";

@Component({
	selector: "kt-orders-edit",
	templateUrl: "./orders-edit.component.html",
	styleUrls: ["./orders-edit.component.scss"],
})
export class OrdersEditComponent implements OnInit {
	totalLength: number = 119;
	pageIndex: number = 1;
	pageSize: number = 10;
	ordersList$: Observable<any[]>;

	statusData: any[] = [];
	statusOptions: any = {};
	statusOptionsConfig: any = {};
	reportForm: FormGroup;
	searchInput: string;
	data$: Observable<any[]>;

	//Pagination
	totalPages: number = 50;
	currentPage: number = 1;
	totalRecords: number = 0;
	itemsPerPage = 10;
	totalItems = 0;
	searchQuery: string = "";
	loading = false;
	loadingSubmit = false;

	minDate: Date;
	maxDate: Date;

	// Sorting Colum in Asc and Desc
    SortColumn: string = 'order_id';
    sortOrderValue = 1;
    SortOrder:string="DESC";

	private ordersListSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
	constructor(private dialog: MatDialog, public snackBar: MatSnackBar, private datePipe: DatePipe, private fb: FormBuilder, private ref: ChangeDetectorRef, private coffeeService: CoffeeService, private spinner: NgxSpinnerService) {
		this.ordersList$ = this.ordersListSubject.asObservable();

		var userdata = JSON.parse(localStorage.getItem("user"));

		this.statusOptionsConfig = {
			ajax: {
				url: environment.BaseUrl + "api/Owner/allDropdown",
				dataType: "json",
				delay: 250,
				headers: {
					Authorization: "Bearer " + userdata.token,
				},
				data: (params: any) => {
					return {
						searchTerm: params.term,
						page: params.page,
						type: 9,
						parentId: 0,
					};
				},
				processResults: (data: any) => {
					const results = data.map((item: any) => {
						return {
							id: item.id,
							text: item.text,
						};
					});
					results.splice(0,0,{id: 0,text: "Show All"});
					return {
						results: results,
					};
				},
				cache: true,
			},
			placeholder: "Select a Status",
			minimumInputLength: 0,
		};

		const currentYear = new Date().getFullYear();
		this.minDate = new Date(currentYear - 20, 0, 1);
		this.maxDate = new Date(currentYear + 1, 11, 31);
	}

	onSubmit() {
		if (this.reportForm.invalid) {
			Object.values(this.reportForm.controls).forEach((control) => control.markAsTouched());
			return;
		}
		const owner_company_id = this.reportForm.get("owner_company_id").value;
		const statusid = this.reportForm.get("statusid").value;
		const orderno = this.reportForm.get("orderno").value;
		const fromDateValue = this.datePipe.transform(this.reportForm.get("fromdate").value, "MM-dd-yyyy");
		const toDateValue = this.datePipe.transform(this.reportForm.get("todate").value, "MM-dd-yyyy");
		this.OrdersGET(fromDateValue, toDateValue, owner_company_id, statusid, orderno);
	}
	resetform() {
		this.reportForm.reset();
		this.OrdersGET(null, null, null, null, null);
	}

	openModal(orderId: number) {
		const dialogRef = this.dialog.open(ViewOrderComponent, {
			data: { orderId: orderId },
		});

		// You can handle dialog events here if needed
		dialogRef.afterClosed().subscribe((result) => {
			console.log("Dialog closed with result:", result);
		});
	}

	ngOnInit() {
		this.reportForm = this.fb.group({
			orderno: [""],
			owner_company_id: [""],
			statusid: [""],
			fromdate: ["", Validators.compose([Validators.required])],
			todate: ["", Validators.compose([Validators.required])],
		});

		this.OrdersGET(null, null, null, null, null);
		this.setupSearch();
	}
	statusChanged(event) {
		console.log("Status Changed:", event);
	}

	OrdersGET(fromDate: string, toDate: string, owner_company_id: string, statusid: string, orderno: string) {
		this.loadingSubmit = true;
		const startIndex = this.currentPage;
		const pageSize = this.itemsPerPage;
		const searchBy = this.searchQuery ? "1" : "0";
		const searchCriteria = this.searchQuery;
		const SortColumn = this.SortColumn;
		const SortOrder=this.SortOrder;

		this.spinner.show();
		this.coffeeService.OrdersGET(startIndex, pageSize, searchBy, searchCriteria, fromDate, toDate, owner_company_id, statusid, orderno,SortColumn,SortOrder).subscribe((response: any[]) => {
			this.ordersListSubject.next(response);
			this.loadingSubmit = false;
			this.totalPages = response[0].TotalPages;
			this.totalRecords = response[0].TotalRecords;
			this.spinner.hide();
		});
	}

	getSelectOptionsConfig(): any {
		var userdata = JSON.parse(localStorage.getItem("user"));
		return {
			ajax: {
				url: environment.BaseUrl + "api/Owner/allDropdown",
				dataType: "json",
				delay: 250,
				headers: {
					Authorization: "Bearer " + userdata.token,
				},
				data: (params: any) => {
					return {
						searchTerm: params.term,
						page: params.page,
						type: 12,
						parentId: "0",
					};
				},
				processResults: (data: any) => {
					const results = data.map((item: any) => {
						return {
							id: item.id,
							text: item.text,
						};
					});
					results.splice(0,0,{id: 0,text: "Show All"});
					return {
						results: results,
					};
				},
				cache: true,
			},
			placeholder: "Select a Owners",
			minimumInputLength: 0,
		};
	}

	setupSearch() {
		//Owner
		this.data$ = new Observable<string>((observer) => {
			observer.next(this.searchInput);
		}).pipe(
			startWith(""),
			debounceTime(300),
			distinctUntilChanged(),
			switchMap((query: string) => {
				console.log("Search Query:", query);
				query = query || "";

				return this.coffeeService.dropdownAll(query, "1", "12", "0");
			})
		);
	}

	onPageChange(page) {
		this.currentPage = page;
		this.OrdersGET(null, null, null, null, null);
	}

	onPageSizeChange(page) {
		this.currentPage = page;
		this.OrdersGET(null, null, null, null, null);
	}
	onSearch(query: string) {
		this.searchQuery = query;
		this.currentPage = 1;
		this.OrdersGET(null, null, null, null, null);
	}

	// check sorting
	sortBy(property: string) {
        this.sortOrderValue = property === this.SortColumn ? (this.sortOrderValue * -1) : 1;
        this.SortColumn = property;
       
		if(this.sortOrderValue ===1)
			{
				this.SortOrder="ASC";		

			}
		if(this.sortOrderValue ===-1)
			{
				this.SortOrder="DESC";				
	
			}

		const owner_company_id = this.reportForm.get("owner_company_id").value;
		const statusid = this.reportForm.get("statusid").value;
		const orderno = this.reportForm.get("orderno").value;
		const fromDateValue = this.datePipe.transform(this.reportForm.get("fromdate").value, "MM-dd-yyyy");
		const toDateValue = this.datePipe.transform(this.reportForm.get("todate").value, "MM-dd-yyyy");
		this.OrdersGET(fromDateValue, toDateValue, owner_company_id, statusid, orderno);
		
		
    }
	sortIcon(property: string) {
        if (property === this.SortColumn) {
         
			return this.sortOrderValue === 1 ? '▲' : '▼';
        }
        return '';
    }

	onValueChange(value: any){
	
		if(value!=''){
		
			const owner_company_id = value;
			const statusid = this.reportForm.get("statusid").value;
			const orderno = this.reportForm.get("orderno").value;
			const fromDateValue = this.datePipe.transform(this.reportForm.get("fromdate").value, "MM-dd-yyyy");
			const toDateValue = this.datePipe.transform(this.reportForm.get("todate").value, "MM-dd-yyyy");
			this.OrdersGET(fromDateValue, toDateValue, owner_company_id, statusid, orderno);
		
		
		}  
	}
	onStatusChange(value: any){
		
		if(value!=''){
		
			const owner_company_id = this.reportForm.get("owner_company_id").value;
			const statusid = value;
			const orderno = this.reportForm.get("orderno").value;
			const fromDateValue = this.datePipe.transform(this.reportForm.get("fromdate").value, "MM-dd-yyyy");
			const toDateValue = this.datePipe.transform(this.reportForm.get("todate").value, "MM-dd-yyyy");
			this.OrdersGET(fromDateValue, toDateValue, owner_company_id, statusid, orderno);
		
		
		}  
	}

}
